import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';

import {Collab, olm, ERcrtUserType, RcrtClub, RcrtClubUser, RcrtUser} from '@nxt/model-rcrt';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {Router} from '@angular/router';
import {IEmptyItem} from '../_components/rcrt-empty-item';

@Injectable()
export class RcrtDataService {
    user$: BehaviorSubject<RcrtUser> = new BehaviorSubject<RcrtUser>(undefined);
    club$: BehaviorSubject<RcrtClub> = new BehaviorSubject<RcrtClub>(undefined);
    cUser$: BehaviorSubject<RcrtClubUser> = new BehaviorSubject<RcrtClubUser>(undefined);
    player$: BehaviorSubject<RcrtUser> = new BehaviorSubject<RcrtUser>(undefined);
    menu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    emailId: string;
    unread: number = 0;
    collab: Collab;
    last_check: number = 0;
    pSub: Subscription;
    cSub: Subscription;
    crSub: Subscription;
    isPlayer: boolean;

    nav: number = 65;
    subnav: number = 41;
    subsubnav: number = 38;

    get navStyle(): any {
        return { top: `${this.nav}px` };
    }

    get subNavStyle(): any {
        let r: any = {};
        if (this.isPlayer || this.user$.getValue()?.collab) {
            r = { top: `${this.nav+this.subnav}px` };
        } else {
            r = { top: `${this.nav}px` };
        }
        return r;
    }

    get profileNavStyle(): any {
        let r: any = {};
        if (this.isPlayer || this.user$.getValue()?.collab) {
            r = { top: `${this.nav+this.subnav+this.subsubnav}px` };
        } else {
            r = { top: `${this.nav+this.subnav}px` };
        }
        return r;
    }

    constructor(
        private fSvc: ConsumerFireService,
        private pSvc: PageService,
        private cSvc: ClientService,
        private lSvc: LocalStorageService,
        private router: Router
    ) {
        this.pSvc.blocking$.next(true);
        this.user$
            .subscribe(async (user:RcrtUser) => {

                if (user !== null) {
                    this.watchClub(user);
                    if (user?.type === ERcrtUserType.PLAYER) {
                        this.isPlayer = true;
                        this.watchPlayer(user?.id);
                    } else {
                        if (user?.collab) {
                            this.watchPlayer(user?.collab);
                        } else {
                            this.isPlayer = false;
                            this.pSub?.unsubscribe();
                            this.player$.next(null);
                            await this.buildDiff();
                        }
                    }
                    this.pSvc.blocking$.next(false);
                }

            });

        this.pSvc.state$
            .subscribe(url => {
                if (url?.match(/account/)) {
                    this.lSvc.saveState('lastPage', url);
                }
            });
    }

    watchPlayer(id: string) {
        this.pSub?.unsubscribe();
        this.pSub = this.fSvc.watchDoc(`users/${id}`, null, true)
            .subscribe(async res => {
                let player: RcrtUser = new RcrtUser( res, olm );
                if (player._exists) {
                    this.isPlayer = player.id === this.user$.getValue()?.id;
                    this.player$.next(player);
                    await this.buildDiff();
                } else {
                    this.isPlayer = false;
                    this.player$.next(null);
                }
                this.pSvc.blocking$.next(false);
            },
                e => {
                    this.player$.next(null);
                // console.error(e);
                },
                () => {
                    this.pSvc.blocking$.next(false);
                }
        );
    }

    watchClub(user: RcrtUser) {
        this.cSub?.unsubscribe();
        if (user?.id && user?.club) {
            this.cSub = this.fSvc.watchDoc(`clubs/${user.club}`, null, true)
                .subscribe(async res => {
                        let club: RcrtClub = new RcrtClub( res, olm );
                        if (club._exists) {
                            this.club$.next(club);
                            this.watchClubUser(user,club);
                        } else {
                            this.club$.next(null);
                            this.watchClubUser(null,null);
                            this.cSub?.unsubscribe();
                        }
                        this.pSvc.blocking$.next(false);
                    },
                    e => {
                        this.club$.next(null);
                        this.watchClubUser(null,null);
                        this.cSub?.unsubscribe();
                    },
                    () => {
                        this.pSvc.blocking$.next(false);
                    }
                );
        } else {
            this.club$.next(null);
            this.watchClubUser(null, null);
        }
    }

    watchClubUser(user:RcrtUser, club: RcrtClub) {
        this.crSub?.unsubscribe();
        if (user?.id && club?.id) {
            let path: string = `clubs/${club.id}/club_users/${user.id}`;
            this.crSub = this.fSvc.watchDoc(path, null, true)
                .subscribe(async res => {
                        let cUser: RcrtClubUser = new RcrtClubUser( res, olm );
                        if (cUser._exists) {
                            if (!this.cUser$.getValue() || this.cUser$.getValue().id !== cUser.id) {
                                this.cUser$.next(cUser);
                                cUser.last_date = Date.now();
                                await this.fSvc.setDoc(cUser);
                            } else {
                                this.cUser$.next(cUser);
                            }
                            this.cUser$.next(cUser);
                        } else {
                            await this.updateUser(user, {club:''});
                            this.cUser$.next(null);
                            this.crSub?.unsubscribe();
                        }
                        this.pSvc.blocking$.next(false);
                    },
                    e => {
                        this.cUser$.next(null);
                        this.crSub?.unsubscribe();
                    },
                    () => {
                        this.pSvc.blocking$.next(false);
                    }
                );
        } else {
            this.cUser$.next(null);
        }
    }

    async buildDiff() {
        let player: RcrtUser = this.player$.getValue();
        if (player?.id) {
            if (this.isPlayer) {
                console.log('player.last_check', player.last_check);
                this.last_check  = !isNaN(Number(player.last_check)) ? player.last_check : 0;
            } else {
                if (!this.collab) {
                    this.collab = await this.cSvc.callAPI(`/collab/list/${player.id}`, 'get');
                }
                this.last_check  = !isNaN(Number(this.collab.last_check)) ? this.collab.last_check : this.last_check;
            }
            let coll = await firstValueFrom(this.fSvc.getColl(
                `users/${player.id}/activity`, [
                    { name: 'orderBy', args: ['date','desc'] },
                    { name: 'where', args: ['date','>', this.last_check ] },
                    { name: 'where', args: ['user_id','!=', this.user$.getValue().id ] },
                    { name: 'limit', args: [1] }
                ]
            ));
            this.unread = coll?.length||0;
        }
    }

    async markAsRead() {
        this.unread = 0;
        if (this.isPlayer) {
            await this.updateUser(this.user$.getValue(), { last_check: Date.now() });
        } else {
            try {
                await this.cSvc.callAPI(`/collab/list/${this.player$.getValue().id}/read`, 'post')
            } catch (e) {
                this.pSvc.notification$.next(e);
            }
        }
    }

    async updateUser(user: RcrtUser, update: any) {
        this.pSvc.blocking$.next(true);
        try {
            Object.assign(user, update);
            await this.fSvc.setDoc(user);
            this.user$.next(user);
        } catch (e) {
            console.error(e);
            this.pSvc.notification$.next(e?.error||e);
        }
        this.pSvc.blocking$.next(false);
    }

    getLastUrl(user: RcrtUser) {
        let d: any = this.lSvc.globalState['nav'] || {};
        let id: string = user?.collab || user?.id;
        let url: string =  d[id]||'';
        // For forced redirect to public event/college page after user signs in/up.
        if (this.lSvc.globalState.overrideLastPage) {
            url = this.lSvc.globalState.overrideLastPage;
            this.lSvc.saveGState('overrideLastPage','');
        }
        return url;
    }

    setLastUrl(user: RcrtUser,url:string) {
        let d: any = this.lSvc.globalState['nav'] || {};
        d[user?.collab || user?.id] = url;
        this.lSvc.saveGState('nav',d);
    }

    showPremium(message?: string) {
        this.pSvc.notification$.next({
            title: 'Premium Feature!',
            message: message || 'This feature requires a paid subscription. Click below for pricing:',
            buttons: [
                {
                    label: 'Subscribe Today!',
                    click: () => {
                        this.router.navigateByUrl(`/pricing`);
                    }
                }
            ]
        });
    }

    buildTeamsEmpty(): IEmptyItem {
        return {
            headline: `First, Set Up Your Teams!`,
            subhead: `Enter the teams you play for in your profile. This makes searching and building share profiles easier.`,
            buttons: [
                {
                    label: `Your Teams`,
                    click: () => {
                        this.router.navigate(['/account'], { queryParams: {p:1,tab:'teams'} });
                    }
                }
            ]
        }
    }

}
