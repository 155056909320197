import {Input, Component} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'spinner',
    template: `
        <div class="w-full">
            <img *ngIf="icon" class="mx-auto animate-pulse" [src]="icon">
            <svg *ngIf="!icon" [class]="'animate-spin h-10 w-10 mx-auto '+class" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>    
        </div>
    `
})
export class SpinnerComponent {
    @Input() class: string = 'mx-auto h-10 w-10 text-dark';
    @Input() icon: string;
}
