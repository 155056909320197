import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import tinycolor from 'tinycolor2';
import cssVars from 'css-vars-ponyfill';

import {ITheme} from '@nxt/model-core';

// @ts-ignore
import {environment} from '@env/environment';

export interface Color {
    name: string;
    hex: string;
    isDarkContrast: boolean;
}

@Injectable()
export class ThemeService {
    themeKey: string = 'theme';
    currentTheme: ITheme = {};

    constructor(@Inject(DOCUMENT) private readonly document: Document) {
    }

    getRandomColorOrContrast(hex?: string) {
        hex = hex || Math.floor(Math.random() * 16777215).toString(16);
        return [this.getColorObject(tinycolor(hex).lighten(30), '').hex, this.getColorObject(tinycolor(hex).darken(20), '').hex];
    }

    // Manually generate the same palette with https://www.tailwindshades.com/
    computeColorPalette(hex: string): Color[] {
        return [
            this.getColorObject(tinycolor(hex), ''),
            this.getColorObject(tinycolor(hex).lighten(45), '50'),
            this.getColorObject(tinycolor(hex).lighten(40), '100'),
            this.getColorObject(tinycolor(hex).lighten(30), '200'),
            this.getColorObject(tinycolor(hex).lighten(20), '300'),
            this.getColorObject(tinycolor(hex).lighten(10), '400'),
            this.getColorObject(tinycolor(hex), '500'),
            this.getColorObject(tinycolor(hex).darken(10), '600'),
            this.getColorObject(tinycolor(hex).darken(20), '700'),
            this.getColorObject(tinycolor(hex).darken(30), '800'),
            this.getColorObject(tinycolor(hex).darken(40), '900'),
        ];
    }

    getColorObject(value: tinycolor.Instance, name: string): Color {
        const c = tinycolor(value);
        return {
            name,
            hex: c.toHexString(),
            isDarkContrast: c.isLight(),
        };
    }

    async setTheme(theme: ITheme, favIcon?: HTMLLinkElement) {
        // try {

            if (!theme || !theme.dark || !theme.light || !theme.accent) {
                theme = environment.default_client.theme;
            }

            this.currentTheme = theme;

            let css: string = `:root {`;
            for (const [name, color] of Object.entries(theme)) {
                const palette = this.computeColorPalette(color);
                for (const variant of palette) {
                    css += `--${name}${variant.name ? '-' + variant.name : ''}: ${variant.hex};`;
                }
            }
            css += `}`;

            const themeEl = this.document.getElementById(this.themeKey);
            if (themeEl) {
                themeEl.innerText = css;
            } else {
                const newThemeStylesEl = this.document.createElement('style');
                newThemeStylesEl.id = this.themeKey;
                newThemeStylesEl.innerText = css;
                this.document.getElementsByTagName('head')[0].appendChild(newThemeStylesEl);
            }

            cssVars({
                onlyLegacy: true,
                watch: false
            });

            if (favIcon) {
                favIcon.href = theme.favicon || '';
            }

        // } catch (e) {
        //     console.error(e);
        // }
    }

}
