import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';

import {Analytics, logEvent} from '@angular/fire/analytics';

import {PageService} from './page.service';
import {Page} from '@nxt/model-core';
import {ClientService} from './client.service';

@Injectable()
export class AnalyticsService {
    ga: any;

    constructor(
        pSvc: PageService,
        aR: ActivatedRoute,
        ga: Analytics,
        private cSvc: ClientService,
        @Inject(DOCUMENT) private document: any
    ) {

        this.ga = ga;// getAnalytics(getApp(environment.appName));

        pSvc.page$.subscribe(
            async (page: Page) => {
                let params: any = await aR.queryParams.pipe(take(1)).toPromise();
                let refer: any = {};
                Object.keys(params).forEach((name: string) => {
                    if (name.match(/utm|gclid|adgroupid|camp|device/i)) {
                        refer[name] = params[name];
                    }
                });
                refer.userAgent = window.navigator.userAgent;
                // console.log(refer);
                sessionStorage.setItem('utm',JSON.stringify(refer));
                if (document?.referrer && !this.document.referrer.match(window.location.hostname)) {
                    sessionStorage.setItem('document.referrer', this.document.referrer);
                }
                if (page?.meta) {
                    // console.log('track');
                    await this.track('mixpanel', 'Page View', {
                        name: page.name || '',
                        title: page.meta.title || '',
                        url: page.url || '',
                        description: page.meta.description || '',
                        tags: page.meta?.tags?.split ? page.meta?.tags?.split(',') : ''
                    });
                }
            });
    }

    setReferrer(o: any) {
        // Parse query string from adwords visitors.
        o.referrer = {formUrl: window.location.pathname};

        if (sessionStorage.getItem('document.referrer')) {
            o.referrer.externalUrl = sessionStorage.getItem('document.referrer');
        }

        if (sessionStorage.getItem('utm')) {
            try {
                o.referrer.search = JSON.parse(sessionStorage.getItem('utm'));
            } catch (e) {
            }
        }
        return o;
    }

    async track(type: 'ga' | 'mixpanel' | 'facebook', event: string, data?: any, custom?: any) {
        try {
            // if (environment.type !== 'prod') {
            //     console.log(event, data);
            // }
            switch (type) {
                case 'ga':
                    if (custom) {
                        Object.keys(custom).forEach((key: string) => {
                            data[key] = custom[key];
                        });
                    }
                    try {
                        await logEvent(this.ga, event, data);
                    } catch (e) {
                        // This try-catch is for IE11 to ignore AngularFireAnalytics errors which is due to javascript issues.
                    }
                    break;
                case 'facebook':
                    if (window && window['fbq']) {
                        window['fbq']('track', event, data);
                    }
                    break;
                case 'mixpanel':
                    if (window && window['mixpanel']) {
                        switch (event) {
                            case 'identify':
                                window['mixpanel'][event](data);
                                break;
                            default:
                                window['mixpanel'].track(event, data);
                                break;
                        }
                    }
                    break;
            }
        } catch (e) {
            // This try-catch is for IE11 to ignore AngularFireAnalytics errors which is due to javascript issues.
        }
    }

}


