import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    Activity,
    CollegeRef,
    Comment,
    Email,
    ERcrtActions,
    olm,
    RcrtEventRef,
    RcrtShare,
    RcrtUser
} from '@nxt/model-rcrt';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {ConsumerScrollableList} from '@library/consumer/list/consumer-scrollable-list';
import {RcrtRatingsAvatar} from '../../account/_components/ratings/rcrt-ratings-avatar';
import {RcrtResultsMetrics} from '../rcrt-results-metrics';
import {PageService} from '@library/shared/_services/page.service';
import {ConsumerFireService, IFirestoreQuery} from '@library/consumer/_services/consumer.fire.service';
import {Router, RouterLink} from '@angular/router';
import {RcrtDataService} from '../../_services/rcrt-data.service';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {take, takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {RcrtEmailReply} from '../../account/_components/email/rcrt-email-reply';
import {RcrtPushService} from '../../_services/rcrt-push.service';
import {DomSanitizer} from '@angular/platform-browser';
import {RcrtActivityItem} from './rcrt-activity-item';
import {slideRightLeft, slideUpDown} from '@library/shared/animations/slideUpDown';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DeviceInfo} from 'ngx-device-detector/lib/device-detector.service';
import {environment} from '@env/environment';
import {pulse} from '@library/shared/animations/pulse';

@Component({
    selector: 'rcrt-activity-dropdown',
    standalone: true,
    imports: [
        CommonModule, IconsComponent, ConsumerScrollableList,
        RcrtRatingsAvatar, RcrtResultsMetrics, PipesModule, RouterLink, RcrtActivityItem
    ],
    providers: [DeviceDetectorService],
    animations: [slideUpDown,slideRightLeft,pulse],
    template: `
        <div *ngIf="player?.id">
            <button [@pulseAnimation]="pulseState"  (click)="toggleMenu()" class="btn-clear btn-sm">
                <icon name="custom-notifications-active" class="h-8 w-8 {{ rdSvc.unread ? 'text-red-600' : 'text-white' }}"></icon>
            </button>

            <div [@slideUpDown]  *ngIf="show$|async" class="absolute top-10 mt-5 flex w-screen max-w-max px-2 right-0">
                <div class="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow shadow-2xl">
                    <div class="bg-white text-accent border-b border-gray-200 p-1 pl-4">
                        <div class="flex justify-between place-content-center">
                            <div class="font-bold uppercase">
                                Account Activity
                            </div>
                            <div>
                                <button class="btn-clear btn-xs" (click)="toggleMenu()">
                                    <icon name="heroicon-outline-x" class="h-6 w-6 text-accent hover:text-black"></icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="max-h-[75vh] overflow-y-auto">

                        <consumer-scrollable-list
                                #scrollableList
                                [itemTemplate]="itemTemplate"
                                [path]="path"
                                [hideEmpty]="true"
                                [baseQuery]="query"
                                [pageSize]="10"
                                [loadAll]="false"
                                [loadAllOpts]="opts"
                        ></consumer-scrollable-list>
                        
                        <div class="italic p-4 text-sm text-gray-400">
                            Account activity, such as new views, clicks, email/share creations, etc are tracked and listed here.
                            <span *ngIf="rdSvc.player$|async"> Activity older than 60 days is removed.</span>
                        </div>
                    </div>
                    <div class="bg-black p-1 w-full flex justify-between">
                        <button class="btn-clear btn-sm text-white hover:text-accent" (click)="rdSvc.markAsRead();hide();">
                            Mark Activity Read
                        </button>
                        <button class="btn-clear btn-sm text-white hover:text-accent" (click)="toggleNotifications();">
                            Notifications {{ pushSvc.token ? 'ON' : 'OFF' }}
                        </button>
                    </div>
                </div>
            </div>

            <div [@slideRightLeft]  *ngIf="notifications$|async"  class="fixed mt-5 flex w-screen max-w-max px-4 right-0">
                <div class="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow shadow-2xl">
                    <div class="bg-white text-accent border-b border-gray-200 p-1 pl-4">
                        <div class="flex justify-between place-content-center">
                            <div class="font-bold uppercase">
                                Push Notifications
                            </div>
                            <div>
                                <button class="btn-clear btn-xs" (click)="toggleNotifications()">
                                    <icon name="heroicon-outline-x" class="h-6 w-6 text-accent hover:text-black"></icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="p-4">

                        <ng-container *ngIf="!pushSvc.token">
                            <div class="text-sm" *ngIf="device.deviceType === 'desktop'">
                                Desktop notifications are not supported. Use your mobile device to access this page.
                            </div>
                            <div class="text-sm" *ngIf="device.deviceType !== 'desktop'">
                                Get immediate notification of new unread items in your account by setting up
                                push notifications.
                            </div>
                            <div class="text-sm mt-3" *ngIf="device.deviceType !== 'desktop'">
                                <ng-container [ngSwitch]="device.os">
                                    <ng-container *ngSwitchCase="'iOS'">
                                        <strong>iOS User Instructions (IMPORTANT):</strong><br/><br/>
                                        <ol class="flex flex-col space-y-2">
                                            <li>1. You must use <strong>SAFARI</strong></li>
                                            <li>2. Open this site in <strong>Safari</strong>, select your browser's Share icon, and select <strong>Add to Home Screen</strong>.</li>
                                            <li>3. Open the app you added in step 2 and login.</li>
                                            <li>4. Click the 'bell' icon at the top-right and select Notifications</li>
                                            <li>5. Click Activate Push button.</li>
                                            <li>6. Click Allow when your phone asks permission.</li>
                                        </ol>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Android'">
                                        <strong>Android User Instructions (IMPORTANT):</strong><br/><br/>
                                        <ol class="flex flex-col space-y-2">
                                            <li>1. You must use <strong>CHROME</strong></li>
                                            <li>2. Open this site in <strong>Chrome</strong>. You should be prompted to install the app. Click Install.</li>
                                            <li>3. Open the app you added in step 2 and login.</li>
                                            <li>4. Click the 'bell' icon at the top-right and select Notifications</li>
                                            <li>5. Click Activate Push button.</li>
                                            <li>6. Click Allow when your phone asks permission.</li>
                                        </ol>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <strong>Unrecognized Mobile OS</strong><br/><br/>
                                        You can try to activate push by selecting the Activate button below, but
                                        performance is not guaranteed to work. Contact support with questions.
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="pushSvc.token">
                            <div class="text-sm">
                                Push is activated on this device. You can de-activate push <strong>for this device</strong> here.
                                If you are not receiving push, try de-activating and re-activating.
                            </div>
                        </ng-container>
                    </div>
                    <div class="bg-black p-1 w-full flex justify-between">
                        <button *ngIf="!pushSvc.token" 
                                class="btn-clear btn-sm text-white hover:text-accent" 
                                (click)="activatePush();">
                            Activate Push
                        </button>
                        <button *ngIf="pushSvc.token" 
                                class="btn-clear btn-sm text-white hover:text-accent" 
                                (click)="deactivatePush()"
                        >
                            De-Activate Push
                        </button>
                    </div>
                </div>
            </div>
            
        </div>

        
        
        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <rcrt-activity-item [item]="item" [player]="player" [user]="rdSvc.user$|async" (onClick)="handleClick($event)"></rcrt-activity-item>
        </ng-template>
    `
})
export class RcrtActivityDropdown extends OnDestroyPage {
    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @ViewChild('scrollableList', {static: false}) scrollableList: ConsumerScrollableList;
    @Input() label: string;
    @Input() player: RcrtUser;
    show: boolean;
    show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    notifications: boolean;
    notifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    placement: 'top-right' = 'top-right';
    opts: any;
    path: string;
    query: IFirestoreQuery[];
    html: any;
    device: DeviceInfo;
    env = environment;
    get pulseState(): string {
        return this.show ? 'active' : 'inactive';
    }

    constructor(
        public pSvc: PageService,
        public rdSvc: RcrtDataService,
        public ddSvc: DeviceDetectorService,
        private fSvc: ConsumerFireService,
        private router: Router,
        private elRef: ElementRef,
        public pushSvc: RcrtPushService
    ) {
        super();

        this.device =  this.ddSvc.getDeviceInfo();
        this.opts = this.fSvc.loadAllOpts(olm);

        this.pSvc.clickEsc$
            .pipe(takeUntil(this.d$))
            .subscribe(e => {
                if (e && this.show) {
                    this.show = false;
                    this.showHideMenu();
                }
            });
        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (this.show
                        && e?.target
                        && this.elRef?.nativeElement
                        && this.elRef.nativeElement.outerHTML.indexOf(e.target['outerHTML']) === -1
                        && (e.target as HTMLElement).id !== "anchor"
                        && (e.target as HTMLElement).id !== "chevron"
                        && (e.target as HTMLElement).nodeName !== "path"
                        && (e.target as HTMLElement).nodeName !== "svg"
                    ) {
                        this.show = false;
                        this.showHideMenu();
                    }
                }
            );

    }

    toggleMenu() {
        this.show = !this.show;
        this.showHideMenu();
        if (this.show && this.player) {
            this.path = `users/${this.player.id}/activity`;
            this.query = [{ name: 'orderBy', args: ['date','desc'] }];
            this.scrollableList?.loadData();
        }
    }

    async handleClick(item?: any) {
        console.log(item?._docRef?.path);
        if (item.info?.url) {
            this.hide();
            await this.router.navigate([item.info.url], item.info.qP ? { queryParams: item.info.qP } : {});
        } else {
            this.pSvc.notification$.next({
                title: 'Oops!',
                message: `Couldn't find the related item!`
            });
        }
    }

    hide() {
        this.show = false;
        this.showHideMenu();
    }

    showHideMenu() {
        switch (this.show) {
            case true:
                this.show$.next(true);
                this.onShow.emit();
                break;
            default:
                setTimeout(() => {
                    this.show$.next(false);
                }, 200);
                break;
        }
    }

    toggleNotifications() {
        this.notifications = !this.notifications;
        if (this.show) {
            this.show = false;
            this.showHideMenu();
        }
        switch (this.notifications) {
            case true:
                this.notifications$.next(true);
                break;
            default:
                setTimeout(() => {
                    this.notifications$.next(false);
                }, 200);
                break;
        }
    }

    async activatePush(confirm?: boolean) {
        switch (this.device.os) {
            case 'iOS':
                switch (this.device.browser) {
                    case 'Safari':
                        if (confirm) {
                            return this.pushSvc.requestPermission();
                        } else {

                            this.pSvc.notification$.next({
                                timeout: 0,
                                title: 'Confirm Home Screen App',
                                message: `Have you added this as an app on your Home Screen?  If you're still in Safari, your activation will not work. See instructions on this page. Click Confirm if you are doing this from the app you added to your home screen. `,
                                buttons: [
                                    {
                                        label: 'Confirmed!',
                                        click: () => {
                                            this.activatePush(true);
                                        }
                                    }
                                ]
                            });

                        }
                        break;
                    default:
                        this.pSvc.notification$.next({
                            title: 'Safari Only!',
                            message: `Push notifications for iOS only work on a web app that you added via Safari.  See instructions on this page.`
                        });
                        break;
                }
                break;
            default:
                return this.pushSvc.requestPermission();
        }
    }

    deactivatePush() {
        this.pushSvc.disable();
    }
}
