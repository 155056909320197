import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    Base, Contact, ECoreCronTasks, IMenuItem, loadObject, QUEUE_ITEM_TYPES,
    QueueItem, round, Thread, ThreadMessage, User
} from '@nxt/model-core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {InputStackedTextAreaComponent} from '@library/shared/input/input-stacked-textarea.component';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {format} from 'date-fns-tz';
import {addDays, addHours, parse, startOfDay} from 'date-fns';
import {FollowersComponent} from '../../threads/_components/followers.component';
import {InputSelectComponent} from '@library/shared/input/input-select.component';
import {UserService} from '@library/nxt/_services/user.service';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {MessagingService} from '@library/nxt/_services/messaging.service';
import {FollowersByRoleComponent} from '../../threads/_components/followers-by-role.component';
import {Router, RouterModule} from '@angular/router';
import {InputDateComponent} from '@library/shared/input/input-date.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, ReactiveFormsModule, InputStackedTextComponent, InputStackedTextAreaComponent,
        FollowersComponent, InputSelectComponent, FooterButtonsComponent, FollowersByRoleComponent, InputDateComponent
    ],
    template: `
        <div class="p-3 pb-20" *ngIf="item && form">
            <div class="mb-2 text-sm italic">
                Select timing of reminder:
            </div>
            <div class="grid {{ options?.length ? 'grid-cols-2' : 'grid-cols-1' }} gap-3">
                <input-select 
                        [class]="(options?.length) ? '' : 'hidden'"
                        [form]="form"
                        [options]="options"
                        (change)="setDate($event)"
                        label="Pre-Defined Options"
                        controlName="options"></input-select>
                <input-date
                        #inputDateComponent
                        [form]="form"
                        label="Manual Date/Time:"
                        controlName="date"
                        [minDate]="today"
                        [includeDate]="true"
                        [requireDate]="true"
                        [includeTime]="true"
                        [requireTime]="true"
                ></input-date>
            </div>
            
            <input-stacked-textarea
                    [form]="form"
                    minHeight="120px"
                    controlName="message"
                    label="Message"></input-stacked-textarea>
            <div class="my-4 text-sm italic">
                You will be automatically assigned as a follower upon saving.
                The agent assigned at the
                time of the reminder will also be added as a follower.
                Any other followers can be added manually:
            </div>

            <div class="mt-3 flex">
                <followers [parent]="item"
                           property="followers"
                           maxlength="10"
                           (onChange)="item.followers=$event;"
                ></followers>
                <div class="ml-3">
                    <followers-by-role
                            [parent]="item"
                            property="roles"
                            (onChange)="item.roles=$event;"
                    ></followers-by-role>
                </div>
            </div>
            
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class NxtFollowUpDialog implements OnChanges {
    @ViewChild('inputDateComponent', { read: InputDateComponent }) inputDateComponent: InputDateComponent;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() parent: Base;
    @Input() item: QueueItem;
    form: UntypedFormGroup;
    options: IMenuItem[];
    buttons: IMenuItem[] = [];
    today: Date = new Date();

    constructor(
        private fb: UntypedFormBuilder,
        private pSvc: PageService,
        private cSvc: ClientService,
        private uSvc: UserService,
        private mSvc: MessagingService,
        private router: Router
        ) {


        this.loadOptions();
    }

    ngOnChanges(changes?: SimpleChanges) {
        this.item = this.item || new QueueItem();
        this.form = this.fb.group({
            message: [this.item?.data?.message||''],
            options: [''],
            date: [this.item.date||null,  [Validators.required]]
        });
        this.buildButtons();
    }

    buildButtons() {
        this.buttons =  [
            {
                label: 'Close',
                class: 'btn-light',
                click: () => {
                    this.onClose.emit();
                }
            },
            {
                label: 'Save',
                class: 'btn-dark',
                click: () => {
                    this.save();
                }
            }
        ];

        if (this.item?._exists) {
            let type: string = 'Item';

            try {
                let parts: string = this.item.data.object?._docRef?.path?.split('/');
                if (parts?.length) {
                    type = parts[parts.length - 2];
                    type = (type.substring(0,1).toUpperCase()+type.substring(1)).replace(/s$/,'');
                }
            } catch (e) {
                console.warn(e);
                type = 'Item'
            }

            this.buttons.push({
                label: `View ${type}`,
                class: 'btn-accent',
                click: () => {
                    this.view();
                }
            });

            this.buttons.push({
                label: 'Process Now',
                class: 'btn-accent ml-6',
                click: () => {
                    this.processNow();
                }
            });

            this.buttons.push({
                label: '',
                class: 'btn-clear',
                icon: { name: 'heroicon-outline-trash', class: 'h-5 w-5 text-red-600'},
                click: () => {
                    this.delete();
                }
            })
        }
    }

    async loadOptions() {
        let config: any = await this.cSvc.callAPI('/config/check', 'post', {type: 'followup'});
        if (config?.manual) {
            this.options = [{
                label: 'Pre-Defined Options:'
            }].concat(config.manual.map(item => {
                return {
                    label: item.label,
                    value: item.days
                }
            }));
        }
    }

    setDate(d?: Date) {
        let date: Date = d;
        let days: number = Number(this.form.get('options').value);
        let hrs: number;
        if (days < 1) {
            hrs = round(days*24,8);
            date = new Date(Date.now() + (hrs * 60 * 60 * 1000));
        } else {
            date = addDays(new Date(), days);
            date = addHours(startOfDay(date), 6);
        }

        this.inputDateComponent.setDate(date);
    }

    addFollower(users: User[]) {
        this.item.add('followers',users);
    }

    async save() {
        if (this.form.valid) {

            try {
                let msg: ThreadMessage = new ThreadMessage(this.item.data);
                Object.assign(msg, this.form.value);
                if (!msg.id) {
                    msg.setID();
                }
                msg.date = this.form.get('date').value.valueOf();
                msg.creator = this.cSvc.client$.getValue();
                msg.subject = `Manual Follow-Up`;
                msg.summary = msg.message;
                if (this.parent instanceof ThreadMessage) {
                    msg.object = this.parent.object;
                    msg.contact = this.parent.contact;
                    msg.tRef = this.parent.tRef;
                } else if (this.parent instanceof Thread) {
                    msg.object = this.parent.object;
                    msg.contact = this.parent.contact;
                    msg.tRef = this.parent._docRef;
                } else if (this.parent) {
                    msg.object = this.parent;
                    if (this.parent instanceof Contact) {
                        msg.contact = this.parent;
                    } else if (this.parent['contact']) {
                        msg.contact = this.parent['contact'];
                    }
                }

                this.item.data = msg;
                this.item.date = this.form.get('date').value.valueOf();
                this.item.type = QUEUE_ITEM_TYPES.FOLLOWUP;
                this.item.creator = this.uSvc.user$.getValue();
                if (!this.item._exists) {
                    this.item.setID();
                }
                await this.item.save(this.cSvc.client$.getValue());

                this.pSvc.loading$.next(false);;

                if (this.parent) {
                    setTimeout(() => {
                        this.pSvc.notification$.next({
                            title: 'Follow-Up Saved!',
                            message: `Notification will be sent on ${format(this.item.data.date, 'EEE, MMM do h:mm a')}`,
                            buttons: [{
                                label: 'View All Follow-Ups',
                                click: () => {
                                    this.router.navigate([`/${this.cSvc.name_key}/threads/inbox/followups`]);
                                }
                            }]
                        });
                    }, 400);
                }

            } catch (e) {
                console.warn(e);
                this.pSvc.alert$.next(e);
            }

            this.onClose.emit();

        } else {
            this.form.markAllAsTouched();
        }
    }

    async view() {
        try {
            this.onClose.emit();
            let o: Base = loadObject(await (this.item.data.tRef||this.item.data.ref).get(), {olm:this.mSvc.olm});
            if (o instanceof Thread) {
                return this.mSvc.nav(o);
            } else if (o instanceof ThreadMessage) {
                return this.mSvc.nav(o);
            } else {
                // Fake a thread so we can re-use nav method.
                let t: Thread = new Thread();
                t.ref = this.item.data.ref;
                await this.mSvc.nav(t, true);
            }
        } catch (e) {
            this.pSvc.alert$.next({
                title: 'Oops',
                message: 'Something went wrong. Please contact an admin.'
            })
        }
    }

    async processNow() {
        this.pSvc.blocking$.next(true);
        try {

            await this.cSvc.callAPI('/cron/trigger', 'post', {path: ECoreCronTasks.FOLLOW_UPS, queue_id: this.item.id, client_id: this.cSvc.client_id});
            this.pSvc.notification$.next({
                title: 'Process Triggered',
                message: 'It may take a few seconds to complete.',
                buttons: [
                    {label: 'View', click: async () => {
                            try {
                                this.onClose.emit();
                                console.log(this.item.data.tRef||this.item.data.ref);

                                let o: Base = loadObject(await (this.item.data.tRef||this.item.data.ref).get(), {olm:this.mSvc.olm});
                                if (o instanceof Thread) {
                                    return this.mSvc.nav(o);
                                } else if (o instanceof ThreadMessage) {
                                    return this.mSvc.nav(o);
                                } else {
                                    // Fake a thread so we can re-use nav method.
                                    let t: Thread = new Thread();
                                    t.ref = this.item.data.ref;
                                    await this.mSvc.nav(t);
                                }
                            } catch (e) {
                                this.pSvc.alert$.next({
                                    title: 'Oops',
                                    message: 'Something went wrong. Please contact an admin.'
                                })
                            }
                        }}
                ]
            });
            // this.onClose.emit();
        } catch (e) {
            this.pSvc.alert$.next(e);
        }
        this.pSvc.blocking$.next(false);
    }

    delete() {

        this.pSvc.alert$.next({
            title: `Are You Sure?`,
            message: 'This cannot be undone.',
            buttons: [
                {
                    label: 'Close',
                    closeOnClick: true
                },
                {
                    label: 'Delete',
                    closeOnClick: true,
                    class: 'btn-dark',
                    click: async () => {
                        await this.item.delete();
                        this.onClose.emit();
                    }
                }
            ]
        })
    }

}
