"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DimensionArray = exports.Dimension = void 0;
const Common_1 = require("./Common");
const Fraction_1 = require("./Fraction");
class Dimension {
    get pounds() {
        return this._pounds || 0;
    }
    set pounds(f) {
        if (f !== undefined && Number(f) !== Number(this._pounds || 0)) {
            this._pounds = Number(f);
        }
    }
    get feet() {
        return this._feet || 0;
    }
    set feet(f) {
        if (f !== undefined && !isNaN(Number(f)) && Number(f) !== Number(this._feet || 0)) {
            this._feet = new Fraction_1.Fraction(Number(f));
            if (Number(this._feet) < 0) {
                this.negative = true;
                this._feet = Number(this._feet || 0) * -1;
            }
            this._feet = Number(this._feet);
            this.validate();
        }
    }
    get inches() {
        return this._inches || 0;
    }
    set inches(f) {
        if (f !== undefined && !(isNaN(Number(f))) && Number(f) !== Number(this._inches || 0)) {
            this._inches = new Fraction_1.Fraction(Number(f));
            if (Number(this._inches) < 0) {
                this.negative = true;
                this._inches = Number(this._inches) * -1;
            }
            if (Number(this._inches) >= 12) {
                this._feet = 0;
                while (Number(this._inches) >= 12) {
                    this._feet = (this._feet || 0) + 1;
                    this._inches = Number(this._inches || 0) - 12;
                }
            }
            let inMod = Number(this._inches) % 1;
            if (inMod) {
                this.fraction = Number(this._fraction || 0) + inMod;
                this.inches = Number(this._inches) - inMod;
            }
            this._inches = Number(this._inches);
            this.validate();
        }
    }
    set fraction(f) {
        if (f instanceof Fraction_1.Fraction) {
            this._fraction = f;
        }
        else {
            if (f && isNaN(Number(f)) === true) {
                f = new Fraction_1.Fraction(f);
            }
            if (f !== undefined && Number(f) !== Number(this._fraction || 0)) {
                this._fraction = new Fraction_1.Fraction(Number(f));
                if (Number(this._fraction) < 0) {
                    this.negative = true;
                    this._fraction = Number(this._fraction || 0) * -1;
                }
                while (Number(this._fraction) > 1) {
                    this._inches = Number(this._inches || 0) + 1;
                    this._fraction = Number(this._fraction) - 1;
                }
                this.validate();
            }
        }
    }
    get fraction() {
        return this._fraction || 0;
    }
    get fraction_string() {
        let result = '';
        if (this._fraction) {
            result = this.fraction.toString(4);
            if (Number(this._fraction)) {
                if ((this._fraction.d && (this._fraction.d === 16 ||
                    this._fraction.d === 8 ||
                    this._fraction.d === 4 ||
                    this._fraction.d === 2))) {
                    result = this._fraction.toFraction(true);
                    ;
                }
            }
        }
        return result;
    }
    get size() {
        return (Number(this.feet || 0) * 12)
            + Number(this.inches || 0)
            + Number(this.fraction || 0);
    }
    get inches_only() {
        return (Number(this.feet || 0) * 12) + Number(this.inches || 0);
    }
    constructor(data) {
        this.name = '';
        this.label = '';
        this.alt = '';
        this.min = null;
        this.max = null;
        this.valid = true;
        this.variable = false;
        this.negative = false;
        this.hide = null;
        this.numeric = true;
        this.options = null;
        this.selectedIndex = 0;
        this.image = '';
        if (data) {
            ['feet', 'inches'].forEach((prop) => {
                if (data[prop]) {
                    this[prop] = Number(data[prop] || 0);
                }
            });
            this.fraction = `${data.fraction_string || data.fraction || 0}`;
            this.name = data.name || '';
            this.alt = data.alt || '';
            this.label = data.label || '';
            this.max = data.max || '';
            this.image = data.image || '';
            this.variable = data.variable;
            this.negative = data.negative || false;
            this.hide = data.hide || false;
            this.numeric = (data.numeric !== undefined) ? data.numeric : true;
            this.validate();
        }
    }
    validate() {
        if (this.min && this.size < this.min) {
            this.valid = false;
        }
        else if (this.max && this.size > this.max) {
            this.valid = false;
        }
        else {
            this.valid = true;
        }
    }
    setSize(inches) {
        this.feet = 0;
        this.fraction = 0;
        this.inches = Math.floor(inches);
        if (inches % 1) {
            this.fraction = (inches % 1);
        }
    }
    subtractDim(dim, tolerance) {
        let r = new Dimension({ name: this.name, variable: this.variable });
        let inches = Number(this.size) - Number(dim.size);
        if (tolerance && inches !== 0) {
            inches = inches - Number(new Fraction_1.Fraction(tolerance).toString(4));
        }
        r.inches = inches;
        return r;
    }
    toString(skipAlt) {
        let r = '';
        if (this.alt && !skipAlt) {
            r = this.alt;
        }
        else if (this.feet) {
            r = `${this.feet}'`;
            if (this.inches) {
                r += ` ${this.inches}`;
                if (this.fraction) {
                    if (this.fraction_string.match(/^0\./)) {
                        r += this.fraction_string.replace('0.', `${this.inches}.`) + '"';
                    }
                    else {
                        r += `-${this.fraction_string}"`;
                    }
                }
                else {
                    r += `"`;
                }
            }
            else if (this.fraction) {
                r += ` ${this.fraction_string}"`;
            }
        }
        else if (this.inches) {
            r = `${this.inches}`;
            if (this.fraction && Number(this._fraction) > 0) {
                if (this.fraction_string.match(/^0\./)) {
                    r = this.fraction_string.replace('0.', `${this.inches}.`) + '"';
                }
                else {
                    r += `-${this.fraction_string}"`;
                }
            }
            else {
                r += `"`;
            }
        }
        else if (this.fraction) {
            r = `${this.fraction_string}"`;
        }
        else {
            r = ``;
        }
        return (this.negative) ? `-${r}` : r;
    }
    toInchesString() {
        let r = '';
        if (!this.numeric && this.alt) {
            r = this.alt;
        }
        else {
            let inches = (Number(this.feet)) ? Number(this.feet) * 12 : 0;
            if (Number(this.inches)) {
                inches += Number(this.inches);
            }
            if (inches) {
                r = `${inches}`;
                if (this.fraction && Number(this._fraction) > 0) {
                    if (this.fraction_string.match(/^0\./)) {
                        r = this.fraction_string.replace('0.', `${inches}.`) + '"';
                    }
                    else {
                        r += `-${this.fraction_string}"`;
                    }
                }
                else {
                    r += `"`;
                }
            }
            else if (this.fraction) {
                r = `${this.fraction_string}"`;
            }
            else {
                r = `0"`;
            }
            r = r.replace(/^0\-/, '');
        }
        return r;
    }
    toFeet() {
        return (0, Common_1.round)(this.size / 12, 2);
    }
    toJSON() {
        return {
            name: this.name,
            hide: this.hide || false,
            feet: Number(this.feet || 0),
            inches: Number(this.inches || 0),
            fraction: this.fraction_string || '',
            variable: this.variable || false,
            negative: this.negative || false,
            numeric: this.numeric || false,
            alt: this.alt || '',
            image: this.image || '',
            max: this.max || ''
        };
    }
}
exports.Dimension = Dimension;
class DimensionArray extends Array {
    constructor(items) {
        super();
        this.negative = false;
        this.description = '';
        if (items && items.length) {
            items.forEach((item, n) => {
                this[n] = new Dimension(item);
            });
        }
        Object.setPrototypeOf(this, DimensionArray.prototype);
    }
    getFeet() {
        let sqFt = 1;
        let inches = 1;
        let varDims = 0;
        // console.log('this.length', this.length);
        if (this.length) {
            this.forEach((dim) => {
                if (dim && dim.variable) {
                    // console.log(dim.name, dim.size/12);
                    inches = inches * (dim.size || 0);
                    sqFt = sqFt * Number(dim.size / 12);
                    varDims++;
                }
            });
        }
        else {
            inches = 0;
        }
        let size = Number(inches);
        sqFt = (varDims > 1) ? Number(sqFt) : null;
        // console.log('sqFt: ',sqFt);
        return (sqFt) ? sqFt : size / 12;
    }
    largestDimension() {
        let largest = new Dimension();
        this.forEach((dim) => {
            if (dim && dim.size > largest.size) {
                largest = new Dimension(dim);
            }
        });
        return largest;
    }
    subtractArrays(dimAA, tolerance) {
        let r = new DimensionArray();
        if (this) {
            this.forEach((dim) => {
                let newDim = dim;
                if (newDim.variable) {
                    dimAA.forEach((otherDimA) => {
                        otherDimA.forEach((otherDim) => {
                            if (otherDim && otherDim.name === dim.name) {
                                newDim = newDim.subtractDim(otherDim, tolerance);
                                if (newDim.negative) {
                                    r.negative = true;
                                }
                            }
                        });
                    });
                }
                r.push(newDim);
            });
        }
        return r;
    }
    toFixedString(skipAlt) {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim && !dim.variable && !dim.hide && dim.toString(skipAlt)) {
                    r.push(dim.toString(skipAlt));
                }
            });
        }
        return r.join(' x ');
    }
    toFixedVerboseString() {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim && !dim.variable && dim.toInchesString()) {
                    r.push(`${dim.name}: ${dim.toInchesString()}`);
                }
            });
        }
        return r.join('; ');
    }
    toFullString() {
        let r = this.toFixedString();
        if (this) {
            if (this.toFixedString() !== this.toFixedString(true)) {
                return `${this.toFixedString()} (${this.toFixedString(true)})`;
            }
        }
        return r;
    }
    toVariableString() {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim && dim.variable) {
                    r.push(dim.toString());
                }
            });
        }
        return r.join(' x ');
    }
    toString() {
        return `${this.toFixedInchesString()} x ${this.toVariableInchesString()}`;
    }
    toFixedInchesString() {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim && !dim.hide && !dim.variable && dim.toInchesString) {
                    r.push(dim.toInchesString());
                }
            });
        }
        return r.join(' x ');
    }
    toVariableInchesString(label) {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim && !dim.hide && dim.variable) {
                    if (label) {
                        r.push(`${dim.name}: ${dim.toInchesString()}`);
                    }
                    else {
                        r.push(dim.toInchesString());
                    }
                }
            });
        }
        return r.join(' x ');
    }
    toJSON() {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim && dim.toJSON) {
                    r.push(dim.toJSON());
                }
                else if (dim) {
                    r.push(dim);
                }
            });
        }
        return r;
    }
    toFixedJSON() {
        let r = [];
        if (this) {
            this.forEach((dim) => {
                if (dim) {
                    if (dim.variable) {
                        dim.feet = 0;
                        dim.inches = 0;
                        dim.fraction = 0;
                    }
                    r.push(dim.toJSON());
                }
            });
        }
        return r;
    }
    get(name) {
        let result;
        if (this) {
            for (let i = 0; i < this.length; i++) {
                if (this[i] && this[i].name === name) {
                    result = this[i];
                    break;
                }
            }
        }
        return result;
    }
    set(name, value) {
        if (this) {
            let found;
            for (let i = 0; i < this.length; i++) {
                if (this[i] && this[i].name === name) {
                    ['feet', 'inches', 'fraction', 'alt', 'max', 'label'].forEach((prop) => {
                        if (value[prop] !== undefined) {
                            this[i][prop] = value[prop];
                        }
                    });
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.push(value);
            }
        }
    }
}
exports.DimensionArray = DimensionArray;
