"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = exports.checkErrors = exports.CLIENT_ERRORS = void 0;
const Common_1 = require("./Common");
const index_1 = require("../index");
exports.CLIENT_ERRORS = {
    settings: {
        id: 'settings',
        priority: 1,
        url: '/company/details',
        urlLabel: 'Configure Company',
        description: `Update your company's name, website, color theme and icons. In your menu, it's under Company -> Details`
    }
};
function checkErrors(client) {
    var _a, _b;
    Object.keys(exports.CLIENT_ERRORS).forEach((key) => {
        client.remove('errors', exports.CLIENT_ERRORS[key]);
    });
    if (!((_a = client === null || client === void 0 ? void 0 : client.theme) === null || _a === void 0 ? void 0 : _a.dark) || !((_b = client === null || client === void 0 ? void 0 : client.theme) === null || _b === void 0 ? void 0 : _b.logo_dark) || !(client === null || client === void 0 ? void 0 : client.website) || !(client === null || client === void 0 ? void 0 : client.name)) {
        client.add('errors', exports.CLIENT_ERRORS.settings);
    }
}
exports.checkErrors = checkErrors;
class Client extends index_1.Base {
    constructor(data, olMap) {
        super('clients', 22);
        this.name = '';
        this.name_key = '';
        this.timezone = 'America/Chicago';
        this.theme = {};
        this.orderStages = [];
        this.address = null;
        this.website = '';
        this.metadata = {};
        this.config = {};
        this.office_hours = {};
        this.phone = '';
        this.email = '';
        this.agents = [];
        this.followers = [];
        this.addresses = new index_1.ChildArray();
        this.third_party = [];
        this.errors = [];
        this._m = ['id', 'active', 'name', 'name_key', 'phone', 'address', 'email', 'website', 'timezone', 'theme', 'office_hours', 'config', 'errors'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Client);
        if (typeof this.config['software'] === 'string') {
            this.config['software'] = { active: true, type: this.config['software'] };
        }
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        return super.loadAll(opts, stack, p);
    }
    async toSearchJSON(opts) {
        var _a;
        // Client is different from others, so we don't use Base.toSearchJSON here.
        let r = this.toMinJSON(true);
        r.objectID = (_a = this._docRef) === null || _a === void 0 ? void 0 : _a.path;
        return r;
    }
    toMiniJSON() {
        let r = { _type: 'clients', _docRef: this._docRef };
        ['id', 'name', 'phone', 'email', 'website', 'theme', 'config'].forEach(p => {
            r[p] = this[p];
        });
        return r;
    }
    async delete() {
        if (this.active) {
            throw `This client cannot be deleted!`;
        }
        else {
            return super.delete();
        }
    }
    toJSON() {
        checkErrors(this);
        return super.toJSON();
    }
}
exports.Client = Client;
index_1.olm.clients = (ref, map) => {
    return new Client(ref, map);
};
