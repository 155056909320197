import {
    NgModule, Component, ViewChild, ViewContainerRef,
    Input, OnChanges, SimpleChanges, Output, EventEmitter
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PageService} from '../_services/page.service';

@Component({
    selector: 'lib-dynamic-loader-component',
    template: `
        <ng-container #targetContainer></ng-container>
    `
})
export class DynamicLoaderComponent implements OnChanges {
    @Input() public component: string;
    componentRef: any;
    @Input() public page: any;
    @Input() public header: any;
    @Input() public subheader: any;
    @Input() public footer: any;
    @Input() public section: any;
    @Input() public params: any;
    @Input() public index: number;
    @Input() public editor: boolean;

    @Output() public onLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() public action: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('targetContainer', {read: ViewContainerRef}) public targetContainer: ViewContainerRef;

    constructor(
        private pSvc: PageService
    ) {
    }

    async setComponent() {
        if (this.component) {
            if (!this.pSvc.lazyComponents[this.component]) {
                console.warn(`Component Missing! ${this.component}`);
            } else if (this.editor && !this.pSvc.lazyComponents[this.component].editor) {
                console.warn(`No editor component for ${this.component}`);
            } else {

                const imported = (typeof this.pSvc.lazyComponents[this.component] === 'function')
                    ? await this.pSvc.lazyComponents[this.component]()
                    : (this.editor)
                        ? await this.pSvc.lazyComponents[this.component].editor()
                        : await this.pSvc.lazyComponents[this.component].loader();

                if (!imported) {

                    console.warn(`Component not configured correctly! ${this.component}`);

                } else {

                    const key = Object.keys(imported).find(key => !key.match(/Module/));
                    // get the first object of the imported js-module
                    let theComp = imported[key];
                    this.componentRef = this.targetContainer.createComponent(theComp);
                    this.setProps();
                    this.onLoaded.emit(this.componentRef);
                }

            }
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['component'] && this.component) {
            this.setComponent();
        }
        this.setProps(changes);
    }

    setProps(changes?: any) {
        if (this.componentRef) {
            Promise.all(['page', 'header', 'subheader', 'section', 'params', 'index'].map(prop => {
                if (!changes || changes[prop]) {
                    this.componentRef.instance[prop] = this[prop];
                }
            }));
            if (this.componentRef.instance.ngOnChanges) {
                this.componentRef.instance.ngOnChanges(changes)
            }
        }
    }

}

@NgModule({
    declarations: [DynamicLoaderComponent],
    imports: [CommonModule],
    exports: [DynamicLoaderComponent]
})
export class LoadableModule {

}
