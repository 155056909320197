"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Feedback = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Feedback extends Base_1.Base {
    constructor(data, olMap) {
        super('feedback', 19);
        this.group_id = '';
        this.title = '';
        this.description = '';
        this.followers = [];
        this.percent = null;
        (0, Common_1.setObjectProperties)(this, data, olMap, Feedback);
    }
}
exports.Feedback = Feedback;
index_1.olm.feedback = (ref, map) => {
    return new Feedback(ref, map);
};
