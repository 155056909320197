"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pricing = void 0;
const Base_1 = require("./Base");
const index_1 = require("../index");
class Pricing extends Base_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'pricing', idLen || 7);
        this.name = '';
        this.description = '';
        this.fn = '';
        this.start = null;
        this.end = null;
        this.readonly = false;
        this.components = null;
        this.metadata = {};
        this._m = ['components', 'name', 'metadata'];
        (0, index_1.setObjectProperties)(this, data, olMap, Pricing);
    }
    toString() {
        return `Pricing: ${this.name}: ${this.description}`;
    }
}
exports.Pricing = Pricing;
index_1.olm.pricing = (ref, map) => {
    return new Pricing(ref, map);
};
