"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RcrtShare = void 0;
const index_1 = require("../index");
const model_core_1 = require("@nxt/model-core");
class RcrtShare extends model_core_1.Base {
    get results() {
        return {
            views: this.results_views
        };
    }
    sharePath() {
        if (this._docRef.parent.parent.parent.id === 'event_refs') {
            return `/public/ev/${this._docRef.parent.parent.parent.parent.id}/${this._docRef.parent.parent.id}/${this.id}`;
        }
        else {
            return `/public/player/${this._docRef.parent.parent.id}/${this.id}`;
        }
    }
    constructor(data, olMap) {
        super('shares', 14);
        this.title = '';
        this.description = '';
        this.orientation = 'square';
        this.ratio = 1;
        this.elements = [];
        this.background = {
            position: 'relative',
            backgroundColor: '',
            borderRadius: '20px'
        };
        this.backgroundImg = '';
        this.user_id = '';
        this.results_views = 0;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, index_1.RcrtEventRef);
    }
    getGameOpponentImg(index) {
        return `/api/profile/${this._docRef.parent.parent.parent.parent.id}/${this._docRef.parent.parent.id}/${this.id}/team/${index}`;
    }
    toJSON() {
        let r = super.toJSON();
        delete r.background.backgroundImage;
        return r;
    }
}
exports.RcrtShare = RcrtShare;
index_1.olm.shares = (ref, map) => {
    return new RcrtShare(ref, map);
};
