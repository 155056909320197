import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, Input, OnChanges,
    Output, QueryList, SimpleChanges, ViewChildren
} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SpinnerComponent} from '../../shared/spinner/spinner.component';
import {AsyncPipe, CommonModule, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {PageService} from '../../shared/_services/page.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {Base, loadObject} from '@nxt/model-core';
import {DateHeaderComponent} from '../../shared/header/date-header.component';
import {PipesModule} from '../../shared/_pipes/pipes';

// @ts-ignore
import {RouterModule} from '@angular/router';
import {ConsumerFireService,IFirestoreQuery} from '../_services/consumer.fire.service';
import {filter, take, takeUntil} from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        PipesModule,
        SpinnerComponent
    ],
    selector: 'consumer-scrollable-list',
    template: `
        <div *ngIf="!(more$|async) && items && !items.length && !hideEmpty"
             class="bg-accent-100 m-4 border-t border-b border-dark-500 text-dark-700 px-4 py-3 flex max-w-400 m-auto mt-4"
        >
            <div class="flex-grow">
                <span class="font-bold">No {{label}} Found. </span>
                <a class="underline cursor-pointer" (click)="items=null;onClear.emit()" *ngIf="source==='a'">Clear Search</a>
            </div>
        </div>
        
        <div [class]="class">
            <div class="item-list w-full">
                <ul role="list" [class]="liClass">
                    <li #itemTemplates *ngFor="let item of items | filterBy: { term: searchTerm, properties: searchProperties }; let i = index; trackBy: pSvc.trackBy()">
                        <ng-container *ngTemplateOutlet="itemTemplate; context:{item:item, items:items, i:i}"></ng-container>
                    </li>
                </ul>
            </div>
            <spinner class="h-6 w-6 text-dark mt-4" *ngIf="!hideSpinner && (more$|async)"></spinner>
        </div>
    `
})
export class ConsumerScrollableList extends OnDestroyPage implements OnChanges, AfterViewInit {
    @ViewChildren('itemTemplates') itemTemplates: QueryList<ElementRef>;
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onLoaded: EventEmitter<number> = new EventEmitter<number>();
    @Output() onTemplateChange: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNextPage: EventEmitter<any> = new EventEmitter<any>();
    @Input() loadAllOpts: any;
    @Input() loadAll: boolean;
    @Input() hideSpinner: boolean = false;
    @Input() path: string;
    @Input() label: string = 'Items';
    @Input() class: string = '';
    @Input() liClass: string = '';
    @Input() pageSize: number = 15;
    @Input() itemTemplate: any;
    @Input() autoStart: boolean = true;
    @Input() searchTerm: string = '';
    @Input() searchProperties: string[] = [];
    @Input() baseQuery: IFirestoreQuery[];
    @Input() items: any[];
    @Input() hideEmpty: boolean;
    @Input() exclude: Base;
    @Input() debug: boolean;
    @Input() watch: boolean;

    more$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    source: 'a' | 'f';
    lastItem: any;
    doneLoading: boolean;
    sortBy: string;
    sub: Subscription;
    delta$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    changed$: Observable<any>;
    observer: IntersectionObserver;

    constructor(
        private fSvc: ConsumerFireService,
        public pSvc: PageService
    ) {
        super();
        this.changed$ = this.delta$.pipe(filter(u => (u===true)));
    }

    // This handles static loader where there is no related algolia search happening first.
    // In the parent compoennt, set the 'source' and 'query' and 'path' properties and the load happens.
    ngOnChanges(changes: SimpleChanges) {
        if (this.path && this.baseQuery && this.autoStart) {
            let delta: any = ['path','baseQuery'].reduce((changed:any,p:string) => {
                if (changes[p] && changes[p].currentValue && changes[p].currentValue !== changes[p].previousValue) {
                    changed[p] = true;
                }
                return changed;
            }, {});

            if (delta.path || delta.baseQuery) {
                this.delta$.next(true);
                this.sub?.unsubscribe();
                this.loadData(true);
            }
        }
    }

    ngOnDestroy() {
        this.observer?.disconnect();
        super.ngOnDestroy();
    }

    ngAfterViewInit() {
        this.itemTemplates.changes
            .pipe(takeUntil(this.d$))
            .subscribe(() => {

                if (this.itemTemplates.length) {
                    if (!(this.itemTemplates.length % this.pageSize)) {
                        const lastItem = this.itemTemplates?.last?.nativeElement;
                        this.observer?.disconnect();
                        this.observer = new IntersectionObserver((entries) => {
                            entries.forEach(entry => {
                                if (entry.isIntersecting) {
                                    if (this.pageSize && !this.more$.getValue()) {
                                        console.log('more',this.doneLoading);
                                        if (!this.doneLoading) {
                                            this.observer.disconnect();
                                            this.more$.next(true);
                                            this.nextPage();
                                        }
                                    }
                                }
                            });
                        });
                        this.observer.observe(lastItem);
                    }
                    this.onTemplateChange.emit(this.itemTemplates?.last);
                }
            });
    }

    async loadData(clear?: boolean) {
        if (clear) {
            this.source = 'f';
            this.items = [];
        }
        if (this.source === 'f') {
            await this.load(this.baseQuery);
        }
    }

    async load(query: IFirestoreQuery[], append?: boolean, path?: string) {
        if (this.path && query) {
            if (!append) {
                this.items = [];
            }
            this.path = path || this.path;
            this.more$.next(true);
            if (this.pageSize) {
                query = query.concat([{name:'limit', args:[this.pageSize]}]);
            }
            this.sortBy = query.find(i => i.name==='orderBy')?.args[0];

            try {
                let obs;
                if (this.watch) {
                    obs = this.fSvc.watchColl(this.path, query).pipe(takeUntil(this.d$))
                } else {
                    obs = this.fSvc.getColl(this.path, query).pipe(take(1));
                }
                obs.subscribe(async res => {
                    if (res) {
                        this.doneLoading = (res.length < this.pageSize);
                        if (!res.length && !append) {
                            this.items = [];
                        } else if (res.length) {
                            this.lastItem = res[res.length - 1];
                            let items: any[] = await Promise.all(res?.map(async item => {
                                item = loadObject(item, this.loadAllOpts);
                                if (this.loadAllOpts && this.loadAll !== false) {
                                    try {
                                        await item.loadAll(this.loadAllOpts);
                                    } catch (e) {
                                        console.warn(`loadAll failure on ${item?._docRef?.path}`, e);
                                    }
                                }
                                return item;
                            }));
                            if (append) {
                                this.items = this.items.concat(items);
                            } else {
                                this.items = items;
                            }
                        }
                    }
                    this.more$.next(false);
                    this.onLoaded.emit(this.items?.length||0);
                },
                    e => {
                        console.warn(e.toString(), this.path);
                        if (e.toString().match(/permission-denied/)) {
                            this.pSvc.notification$.next({
                                title: 'Permission Denied',
                                message: `You do not have permissions configured for requested content. Please contact an admin for help.`
                                // TODO Would be nice to have an email link or way to create a ticket.
                            })
                        }
                        this.more$.next(false);
                    });

            } catch (e) {
                console.error(e);
            }

        }
    }

    async handleAlgoliaResults([results, append]) {
        this.source = 'a';

        let loadItem = (item:any) => {
            let i: Base = loadObject(item, this.loadAllOpts);
            let parts: string[] = item['objectID'].split('-');
            i._docRef = this.fSvc.getDocRef(parts[1]);
            Object.keys(item['_highlightResult']).forEach(p => {
                if (item['_highlightResult'][p].matchLevel==='full') {
                    i[`${p}_match`] = item['_highlightResult'][p].value;
                }
            });
            return i;
        };

        if (!append) {
            if (results?.hits?.length) {
                this.items = results?.hits?.reduce((items,item) => {
                    if (!this.exclude || item.id !== this.exclude.id) {
                        items.push(loadItem(item))
                    }
                    return items;
                },[]);
            } else {
                this.items = [];
            }

        } else if (results?.hits?.length) {
            this.items = this.items.concat(results?.hits?.reduce((items,item) => {
                if (!this.exclude || item.id !== this.exclude.id) {
                    items.push(loadItem(item))
                }
                return items;
            },[]));
        }
        this.more$.next(false);
    }

    nextPage() {
        if (this.source === 'f') {
            if (this.lastItem) {
                let q: IFirestoreQuery[] = this.baseQuery.concat([{name: 'startAfter', args: [this.lastItem]}]);
                this.load(q, true);
            } else {
                this.more$.next(false);
            }
        } else {
            this.onNextPage.emit();
            this.more$.next(false);
        }
    }

}
