import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RcrtDownloadIcsButton} from '../_components/ics/rcrt-download-ics-button';
import {RcrtHeader} from '../_components/header/rcrt-header';
import {RcrtFooter} from '../_components/footer/rcrt-footer';
import {environment} from '@env/environment';
import {RcrtNotFound} from '../_components/rcrt-not-found';

@Component({
    selector: 'rcrt-404',
    standalone: true,
    imports: [
        CommonModule,
        RcrtDownloadIcsButton,
        RcrtHeader,
        RcrtFooter,
        RcrtNotFound
    ],
    template: `
        <rcrt-not-found></rcrt-not-found>
        
    `
})
export class RcrtPageNotFound {
    env = environment;

    constructor(
    ) {

    }

}
