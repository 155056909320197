"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueItem = exports.QUEUE_ITEM_TYPES = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
var QUEUE_ITEM_TYPES;
(function (QUEUE_ITEM_TYPES) {
    QUEUE_ITEM_TYPES["BIGQUERY"] = "BIGQUERY";
    QUEUE_ITEM_TYPES["FOLLOWUP"] = "FOLLOWUP";
    QUEUE_ITEM_TYPES["MERGE"] = "merge";
    QUEUE_ITEM_TYPES["MARKETPLACE_SUBSCRIPTION"] = "sub";
    QUEUE_ITEM_TYPES["AVIANIS_TRIP"] = "avianis-trip";
    QUEUE_ITEM_TYPES["MICROSOFT_SUBSCRIPTION"] = "microsoft";
    QUEUE_ITEM_TYPES["IMPORT"] = "import";
    QUEUE_ITEM_TYPES["RCRT_ADD"] = "rcrt-add";
    QUEUE_ITEM_TYPES["RCRT_ADD_TEAM"] = "rcrt-add-team";
    QUEUE_ITEM_TYPES["RCRT_ADD_GAMES"] = "rcrt-add-games";
    QUEUE_ITEM_TYPES["RCRT_ADD_STAFF"] = "rcrt-add-staff";
    QUEUE_ITEM_TYPES["RCRT_ADD_SEASON"] = "rcrt-add-season";
    QUEUE_ITEM_TYPES["NONE"] = "";
})(QUEUE_ITEM_TYPES || (exports.QUEUE_ITEM_TYPES = QUEUE_ITEM_TYPES = {}));
class QueueItem extends Base_1.Base {
    constructor(data, olMap) {
        var _a;
        super('queue_items', 36);
        this.type = QUEUE_ITEM_TYPES.NONE;
        this.action = '';
        this.path = '';
        this.class_name = '';
        this.data = {};
        this.creator = null;
        this.followers = [];
        this.agents = [];
        this.roles = [];
        (0, Common_1.setObjectProperties)(this, data, olMap, QueueItem);
        if ((_a = this.data) === null || _a === void 0 ? void 0 : _a.object) {
            this.data.object = (0, index_1.loadObject)(this.data.object, { olm: olMap || index_1.olm });
            this.data.contact = (0, index_1.loadObject)(this.data.contact, { olm: olMap || index_1.olm });
            if (this.data.object && this.data.contact && !(this.data.object instanceof index_1.Contact)) {
                this.data.object.contact = this.data.contact;
            }
        }
    }
    async loadAll(opts, stack, p) {
        await super.loadAll(opts, stack, p);
        if (this.type === QUEUE_ITEM_TYPES.FOLLOWUP) {
            this.data = new index_1.ThreadMessage(this.data);
            // opts.properties = this.data._refProps;
            await this.data.loadAll(opts, stack, p);
        }
    }
    toString() {
        return `QueueItem: ${this.type}: ${this.action}: ${this.path}`;
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        if ((_a = this.data) === null || _a === void 0 ? void 0 : _a.toJSON) {
            r.data = this.data.toJSON();
        }
        return r;
    }
}
exports.QueueItem = QueueItem;
index_1.olm.queue_items = (ref, map) => {
    return new QueueItem(ref, map);
};
