import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'img[fallback]',
    standalone: true
})
export class ImgFallbackDirective {
    @Input() appImgFallback: string;
    constructor(private eRef: ElementRef) { }
    @HostListener('error')
    loadFallbackOnError() {
        const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
        element.src = this.appImgFallback || '/assets/empty.png';
    }

}
