import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterLink} from '@angular/router';

import {Client, User} from '@nxt/model-core';
import {ClientService} from '@library/shared/_services/client.service';
import {RcrtDataService} from '../../_services/rcrt-data.service';
import {PageService} from '@library/shared/_services/page.service';
import {RcrtTosViewerDialog} from './rcrt-tos-viewer';
import {take} from 'rxjs/operators';

@Component({
    selector: 'rcrt-tos-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        RcrtTosViewerDialog
    ],
    template: `
<!--        <div class="z-[100] absolute top-0 w-full bg-black text-white p-4 text-center" *ngIf="showTerms">-->
<!--            Please review and agree to Terms of Service. <a class="cursor-pointer underline" (click)="launchTOS()">Click here</a>.-->
<!--        </div>-->
    `
})
export class NxtTosHeader {
    user: User;
    terms: any;
    client: Client;

    constructor(
        public cSvc: ClientService,
        public rdSvc: RcrtDataService,
        private pSvc: PageService
    ) {

        this.rdSvc.user$
            .subscribe(
                async (user) => {
                    if (user?._exists && !this.user) {
                        this.user = user;
                        this.client = await this.cSvc.callAPI(`/rcrt/client`, 'get');
                        this.terms = this.client.config?.terms;
                        this.doCheck();
                    }
                });

    }

    async doCheck(repeat?: boolean) {
        if (
            this.terms?.version
            && this.user?.id
            && !this.user.terms.find(t => t.version === this.terms.version)
        ) {

            if (repeat) {
                this.pSvc.notification$.next({
                    title: 'Must Agree to Terms!',
                    message: `You must agree to our terms of service to continue.  Thanks!`
                });
            }
            this.launchTOS();
        }
    }

    launchTOS() {
        this.pSvc.modal$.next({
            label: (this.user.terms?.length) ? 'Updated Terms of Service' : 'Terms of Service',
            component: RcrtTosViewerDialog,
            onLoaded: (comp: RcrtTosViewerDialog) => {
                comp.terms = this.terms;
                comp.user = this.user;
                comp.ngOnInit();
                comp.onClose.pipe(take(1))
                    .subscribe(e => {
                        this.doCheck(true);
                    });
            }
        });
    }
}
