import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import {Client, User, IClient, Contact} from '@nxt/model-core';
// @ts-ignore
import {environment} from '@env/environment';

@Injectable()
export class ClientService {
    signedOut: Observable<any>;
    u$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    c$: BehaviorSubject<IClient> = new BehaviorSubject<IClient>(environment.default_client);
    client$: BehaviorSubject<Client> = new BehaviorSubject<Client>(undefined);
    clients$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>(undefined);
    terms$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    user: User;
    contact: Contact;

    constructor(
        private http: HttpClient
    ) {
    }

    get client_id(): string {
        return this.c$.getValue()?.id;
    }

    get name_key(): string {
        return this.c$.getValue()?.name_key;
    }

    async callAPI(path: string, method: 'get' | 'post' | 'delete', data?: any, token?: any): Promise<any> {

        token = token || await this.u$.getValue()?.getIdToken() || '';
        const reqOpts: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                client: this.c$.getValue().id,
                token: token
            })
        };

        switch (method) {
            case 'delete':
                return this.http.delete(`/api${path}`, reqOpts).toPromise();
            case 'post':
                return this.http.post(`/api${path}`, data, reqOpts).toPromise();
            case 'get':
                return this.http.get(`/api${path}`, reqOpts).toPromise();
        }
    }

}


