import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {round} from '@nxt/model-core';

@Directive({
    standalone: true,
    selector: '[scrollable]'
})
export class ScrollableDivDirective {
    @Output() onScroll: EventEmitter<number> = new EventEmitter<number>();
    @Input() threshhold: number = .9;

    constructor(public el: ElementRef) {}

    @HostListener('scroll', ['$event'])
    onListenerTriggered(event: UIEvent) {
        let p: number = round(Math.ceil(this.el?.nativeElement?.offsetHeight + this.el?.nativeElement?.scrollTop) / this.el?.nativeElement?.scrollHeight, 2);
        if (!((p * 100) % 5)) {
            if ( p >= this.threshhold) {
                this.onScroll.emit(p);
            }
        }
    }
}
