"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collab = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Collab extends model_core_1.Base {
    get results() {
        return {
            views: this.results_views
        };
    }
    constructor(data, olMap) {
        super('collabs', 12);
        this.email = '';
        this.token = '';
        this.accepted = null;
        this.last_check = 0;
        this.results_views = 0;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Collab);
    }
}
exports.Collab = Collab;
index_1.olm.collabs = (ref, map) => {
    return new Collab(ref, map);
};
