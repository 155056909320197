"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCard = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
class CreditCard extends Base_1.Base {
    /**
     * CARD OBJECT
     *  { id: 'card_1DTbgEIJbM465JT0ohNZlBVf',
          object: 'card',
          address_city: 'Texarkana',
          address_country: null,
          address_line1: '111 Main St',
          address_line1_check: 'pass',
          address_line2: '',
          address_state: 'AR',
          address_zip: '75502',
          address_zip_check: 'pass',
          brand: 'Visa',
          country: 'US',
          customer: 'cus_DvSbagvM540nJJ',
          cvc_check: 'pass',
          dynamic_last4: null,
          exp_month: 5,
          exp_year: 2020,
          fingerprint: 'WgAATHqBGIOmgc4U',
          funding: 'credit',
          last4: '4242',
          metadata: {},
          name: 'Stuart Young',
          tokenization_method: null }

     */
    constructor(data, olMap) {
        super('cards', 13);
        this.name = '';
        this.first_name = '';
        this.last_name = '';
        this.street = '';
        this.street2 = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.flag = '';
        this.notes = '';
        this.email = '';
        this.address_id = '';
        this.metadata = {};
        this.expired = false;
        this.default = false;
        this.contact_id = '';
        this.stripe_customer_id = '';
        this.last4 = '';
        this.exp_month = '';
        this.exp_year = '';
        this.brand = '';
        this._m = ['brand', 'last4', 'name', 'first_name', 'last_name', 'exp_month', 'exp_year', 'stripe_customer_id'];
        (0, Common_1.setObjectProperties)(this, data, olMap, CreditCard);
        this.active = (this.active === null) ? true : this.active;
    }
    toString() {
        return ['brand', 'last4'].reduce((r, p) => {
            if (this[p]) {
                r.push(this[p]);
            }
            return r;
        }, []).join(', ');
    }
    validate() {
        if (!this.zip) {
            throw { title: 'Card Incomplete', message: 'Must select a billing address.' };
        }
        else if (!this.name) {
            throw { title: 'Card Incomplete', message: 'Must include name.' };
        }
        else if (!this.street || !this.city || !this.state || !this.zip) {
            throw { title: 'Address Incomplete', message: 'Must include street, city, state, and zip in address.' };
        }
    }
}
exports.CreditCard = CreditCard;
index_1.olm.cards = (ref, map) => {
    return new CreditCard(ref, map);
};
