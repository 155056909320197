"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudFile = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class CloudFile extends Base_1.Base {
    constructor(data, olMap) {
        super('files', 10);
        this.name = '';
        this.type = '';
        this.url = '';
        this.lastModified = null;
        this.size = null;
        this.third_party = '';
        this.locked = false;
        this.metadata = {};
        (0, Common_1.setObjectProperties)(this, data, olMap, CloudFile);
        if (!this.date) {
            this.date = this.lastModified || Date.now();
        }
    }
    toJSON() {
        let r = super.toJSON();
        if (!r.date) {
            r.date = r.lastModified || Date.now();
        }
        return r;
    }
}
exports.CloudFile = CloudFile;
index_1.olm.files = (ref, map) => {
    return new CloudFile(ref, map);
};
