import {animate, style, transition, trigger} from '@angular/animations';
export const slideUpDown =  [
    trigger('slideUpDown', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)' }),
            animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({ transform: 'translateY(-100%)' })),
        ]),
    ]),
];
export const slideLeftRight =  [
    trigger('slideLeftRight', [
        transition(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({ transform: 'translateX(-100%)' })),
        ]),
    ]),
];

export const slideRightLeft =  [
    trigger('slideRightLeft', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({ transform: 'translateX(100%)' })),
        ]),
    ]),
];
