import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PageService} from '../_services/page.service';
import {Page} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {LoadableModule} from './dynamic.loader.component';

@Component({
    standalone: true,
    imports: [CommonModule, LoadableModule],
    selector: 'flex-page-component',
    template: `
        <ng-container *ngIf="!(pSvc.loading$ | async) && page?.content?.sections">

            <ng-container *ngFor="let section of page.content.sections">

                <lib-dynamic-loader-component
                        *ngIf="!section.column && section.column!==0 && !section.hide"
                        [component]="section.selector"
                        [page]="page" [section]="section"></lib-dynamic-loader-component>

            </ng-container>

            <div [class]="page.content.columnWrapper">

                <div *ngFor="let col of page.content.columns; let i = index;" [class]="(col) ? col : ''">

                    <ng-container *ngFor="let section of page.content.sections">

                        <lib-dynamic-loader-component
                                *ngIf="section.column===i && !section.hide"
                                [component]="section.selector"
                                [page]="page" [section]="section"
                        >
                            <!--                            <div [ngStyle]="getStyle(section)" loading>&nbsp;</div>-->
                            <!--                            <div [ngStyle]="getStyle(section)" error>&nbsp;{{ section.selector }}</div>-->
                        </lib-dynamic-loader-component>

                    </ng-container>
                </div>

            </div>

            <ng-container *ngFor="let section of page.content.sections">

                <lib-dynamic-loader-component
                        *ngIf="section.column==='below' && !section.hide"
                        [component]="section.selector"
                        [page]="page" [section]="section"
                >
                    <!--                <div [ngStyle]="getStyle(section)" loading>&nbsp;</div>-->
                    <!--                <div [ngStyle]="getStyle(section)" error>&nbsp;{{ section.selector }}</div>-->
                </lib-dynamic-loader-component>

            </ng-container>
        </ng-container>
    `
})
export class FlexComponent implements OnChanges {
    @Input() page: Page;

    constructor(public pSvc: PageService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('this.page', this.page);
    }
}
