import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RcrtDownloadIcsButton} from '../_components/ics/rcrt-download-ics-button';
import {RcrtHeader} from '../_components/header/rcrt-header';
import {RcrtFooter} from '../_components/footer/rcrt-footer';
import {environment} from '@env/environment';

@Component({
    selector: 'rcrt-not-found',
    standalone: true,
    imports: [
        CommonModule,
        RcrtDownloadIcsButton,
        RcrtHeader,
        RcrtFooter
    ],
    template: `
        <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div class="text-center">
                <p class="text-base font-semibold text-accent-600">404</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p class="mt-6 text-base leading-7 text-gray-600">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
                <p class="mt-6 text-base leading-7 italic font-bold text-gray-600" *ngIf="dev">
                    Not all colleges are replicated in the dev environment, to save db space.
                </p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a href="#"
                       class="rounded-md bg-accent-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600">Go
                        back home</a>
                    <a href="mailto:{{env.support}}" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a>
                </div>
            </div>
        </main>
    `
})
export class RcrtNotFound {
    @Input() dev: boolean;
    env = environment;

    constructor(
    ) {

    }

}
