import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Address, User, OrderItem} from '@nxt/model-core';
import {AnalyticsService} from './analytics.service';
import {PageService} from './page.service';
import {ClientService} from './client.service';
import {LocalStorageService} from './local-storage.service';
import {ConsumerFireService} from '../../consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '../../consumer/_services/consumer.account.service';

export interface ICartDocument {
    exists?: boolean;
    checkout: any;
    receipts?: string[];
    user?: User;
    address?: Address;
    date?: number;
}

@Injectable()
export class CartService {
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    calculating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    calculatingShipping$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    cartDocument$: BehaviorSubject<ICartDocument | null> = new BehaviorSubject<ICartDocument | null>(null);
    activeItem$: BehaviorSubject<OrderItem | null> = new BehaviorSubject<OrderItem | null>(null);
    watchers: any = {};

    constructor(
        public aSvc: AnalyticsService,
        public pSvc: PageService,
        public cSvc: ClientService,
        public lSvc: LocalStorageService,
        public fSvc: ConsumerFireService,
        public acctSvc: ConsumerAccountService
    ) {
        // The cart service must always have a signed-in user.
        // Therefore, sign in anonymously if needed.
        // this.loading$.next(true);
        this.cSvc.u$.subscribe(async u => {
            if (u?.uid) {
                this.watch(u);
            }
            // this.loading$.next(false);
        });
    }

    async watch(u:any) {
        if (u?.uid) {
            await Promise.all(Object.keys(this.watchers).map(async id => {
                if (this.watchers[id]?.unsubscribe) {
                    await this.watchers[id]?.unsubscribe();
                }
                delete this.watchers[id];
            }));
            this.watchers[u.uid] = this.fSvc.watchDoc(`carts/${u.uid}`, null, true)
                .subscribe(c => {
                    this.emitDocument(c);
                    setTimeout(() => {
                        this.loading$.next(false);
                    }, 1000);
                }, e => {
                    console.error(e);
                }, () => {
                });
        }
    }

    loadDocument(document: ICartDocument): ICartDocument {
        document.receipts = document.receipts || [];
        document.user = new User(document.user);
        document.address = new Address(document.address);
        ['first_name', 'last_name', 'company', 'email', 'phone'].forEach(p => {
            document.address[p] = document.address[p] ? document.address[p] : document.user ? document.user[p] : '';
        });
        this.setItemCount(document);
        return document;
    }

    emitDocument(c: any) {
        let document: ICartDocument = c?.exists() ? c.data() : {};
        document.exists = c?.exists()||false;
        this.loadDocument(document);
        this.cartDocument$.next(document);
    }

    copyCart(document: ICartDocument) {
        if (document) {
            let copy: any = Object.assign({}, this.toFullJSON(document));
            return this.loadDocument(copy);
        }
        return document;
    }

    async calculateTax(latest: ICartDocument) {
        if (latest?.address?.zip) {
            try {
                let tax: any = await this.cSvc.callAPI('/cart/calculateTax', 'post', {
                    order: latest.checkout.toFullJSON(),
                    address: latest.address.toJSON()
                });
                latest.checkout.tax = tax;
            } catch (e) {
                console.warn(e);
            }
        }
    }

    async saveCartItem(cart: ICartDocument, item: OrderItem, skipSave?: boolean, skipShip?: boolean) {
        let i: number = this.findIndex(item, cart);
        cart.checkout.items = cart.checkout.items || [];
        if (i > -1) {
            cart.checkout.items[i] = item;
        } else {
            // If item is a subscription, allow only one subscription in cart.
            if (item.product?.subscription && cart.checkout.items?.find(i => i.product?.subscription)) {
                this.pSvc.notification$.next({ title: 'Oops!', message: `Cannot add item. Cart already contains a subscription.`});
            } else {
                cart.checkout.items.push(item);
                this.aSvc.track('mixpanel', 'Added to Cart', item);
                this.aSvc.track('ga', "add_to_cart", item);
            }

        }

        cart.checkout.calc();
        if (!skipSave) {
            await this.save(cart, false, skipShip);
        }
    }

    async removeCartItem(cart: ICartDocument, item: OrderItem, skipSave?: boolean, skipShip?: boolean) {
        let i: number =  this.findIndex(item, cart);
        cart.checkout.items = cart.checkout.items || [];
        if (i > -1) {
            cart.checkout.items.splice(i, 1);
            if (!skipSave) {
                await this.save(cart, false, skipShip);
            }
        }
        this.aSvc.track('ga', 'removed_from_cart', item);
        this.aSvc.track('mixpanel', 'Removed from Cart', item);
    }

    setItemCount(cart: ICartDocument) {
        this.lSvc.saveState('itemCount', cart?.checkout?.items?.reduce((n, i) => {
            n += Number(i.quantity);
            return n;
        }, 0));
    }

    async save(cart: ICartDocument, skipCalc?: boolean, skipShip?: boolean) {
        this.calculating$.next(true);
        if (cart.checkout && !skipCalc) {
            cart.checkout?.calc();
            if (!skipShip) {
                await this.calculateShipping(cart);
            }
            await this.calculateTax(cart);
        }

        cart.checkout?.calc();
        await this.saveToFb(cart);
        this.calculating$.next(false);
        this.loading$.next(false);
    }

    async saveToFb(cart: ICartDocument) {
        if (this.cSvc.u$.getValue()) {
            try {
                await this.fSvc.set(`carts/${this.cSvc.u$.getValue().uid}`, this.toFullJSON(cart));
            } catch (e) {
                try {
                    console.warn(`CART SAVE ERROR: ${e.toString()}`,  this.toFullJSON(cart));
                } catch (er) {
                    console.warn(`CART SAVE ERROR 2`,  e);
                }
            }
            this.watch(this.cSvc.u$.getValue());
        } else {
            console.warn(`Cannot save cart! No user`);
        }
    }

    async calculateShipping(latest: ICartDocument, a?: Address, force?: boolean) {
        let existing: ICartDocument = this.cartDocument$.getValue();
        let delta: boolean;
        if (latest.checkout?.subtotal && (latest?.checkout?.subtotal && latest.checkout.subtotal !== existing?.checkout?.subtotal)) {
            delta = true;
        }
        if (latest.checkout?.address?.zip && (latest.checkout?.address?.zip !== existing?.checkout?.address?.zip)) {
            delta = true;
        }
        // console.log('discount',`${latest.checkout?.discount?.amount} !== ${existing?.checkout?.discount?.amount}`);
        if (latest?.checkout?.discount?.amount && latest.checkout.discount?.amount !== existing?.checkout?.discount?.amount) {
            delta = true;
        }

        if (delta || a || force) {
            a = a || latest.checkout?.address;
            if (a?.zip || force) {
                this.calculatingShipping$.next(true);
                try {
                    let result: any = await this.cSvc.callAPI('/cart/priceShipping', 'post', {
                        order: latest.checkout?.toFullJSON(),
                        address: a?.toJSON ? a.toJSON() : a
                    });
                    latest.checkout.selected_rate = result?.selected_rate || {};
                    latest.checkout.shipments = result?.shipments?.map(s => {
                        delete s.items;
                        return s;
                    }) || [];
                    latest.checkout.address = a;
                    latest.checkout.shipping = result?.shipping || 0;
                    latest.checkout.metadata = result?.metadata || {};
                    latest.checkout.calc();

                } catch (e) {
                    console.warn(e);
                    this.pSvc.alert$.next(e);
                }
                this.calculatingShipping$.next(false);
            }
        }
    }

    toFullJSON(cart: ICartDocument) {
        return {
            date: Date.now(),
            user: cart.user?.toJSON ? cart.user?.toJSON() : null,
            address: cart.address?.toJSON ? cart.address?.toJSON() : null,
            checkout: cart.checkout?.toFullJSON() || {},
            receipts: cart.receipts || []
        }
    }

    inCart(item: OrderItem, cart: ICartDocument) {
        return this.findItem(item, cart) ? true : false;
    }

    findItem(item: OrderItem, cart: ICartDocument): OrderItem {
        let i: number =  this.findIndex(item, cart);
        if (i > -1) {
            return cart.checkout?.items[i];
        } else {
            return;
        }
    }

    findIndex(item: OrderItem, cart: ICartDocument): number {
        return cart?.checkout?.items?.findIndex(i => i.id === item.id);
    }
}
