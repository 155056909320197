import {ElementRef, Inject, Injectable, Optional} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {HttpClient} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject, Subject} from 'rxjs';

import {IFcmNotificationPayload, IMenu, IMenuItem, Page} from '@nxt/model-core';
import {RightClickMenu} from '../right-click/right-click.menu';

import {ClientService} from './client.service';
import {LocalStorageService} from './local-storage.service';

// @ts-ignore
import {environment} from '@env/environment';
import {FeedbackItemsComponent} from '../../nxt/feedback/feedback-items.component';

@Injectable()
export class PageService {
    nav$: Subject<boolean> = new Subject();
    click$: Subject<PointerEvent> = new Subject<PointerEvent>();
    clickEsc$: Subject<boolean> = new Subject<boolean>();
    state$: BehaviorSubject<string> = new BehaviorSubject('');
    alert$: BehaviorSubject<IAlert | any> = new BehaviorSubject<IAlert | any>(null);
    notification$: BehaviorSubject<IAlert> = new BehaviorSubject<IAlert>(null);
    modal$: BehaviorSubject<IModalOptions> = new BehaviorSubject<IModalOptions>(null);
    page$: BehaviorSubject<Page> = new BehaviorSubject<Page>(null);
    pageLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    pageNotFound$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    dimensions$: BehaviorSubject<IScreenDimensions> = new BehaviorSubject<IScreenDimensions>(null);
    thirdCol$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    roles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
    menu$: BehaviorSubject<IMenu> = new BehaviorSubject<IMenu>(null);

    submenu$: BehaviorSubject<IMenuItem | IMenuItem[]> = new BehaviorSubject<IMenuItem | IMenuItem[]>(null);
    activeStack$: BehaviorSubject<IMenuItem[]> = new BehaviorSubject<IMenuItem[]>(null);

    blocking$: BehaviorSubject<any> = new BehaviorSubject(null);
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    spin: any = {};
    path$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    queryParams: any = {};
    images: any = {};
    apis: any = {};

    schema: any;
    copiedSection: any;
    lazyComponents: any = {};

    public jsonld: BehaviorSubject<any> = new BehaviorSubject(null);
    escapeKey: any = {
        add: (item: any) => {
            this.escapeKey.stack.push(item);
        },
        remove: (id: string) => {
            for (let i = this.escapeKey.stack.length - 1; i >= 0; i--) {
                if (this.escapeKey.stack[i].id === id) {
                    this.escapeKey.stack.splice(i, 1);
                }
            }
        },
        stack: []
    };

    // params$: any;

    constructor(
        public http: HttpClient,
        public meta: Meta,
        public titleService: Title,
        private router: Router,
        private cSvc: ClientService,
        private lSvc: LocalStorageService,
        @Inject(DOCUMENT) private document: any,
        @Inject('MenuService') private menuSvc: any,
        @Inject('LazyComponents') addComponents: any[],
        private breakpointObserver: BreakpointObserver
    ) {

        addComponents.forEach(lazy => {
            Object.keys(lazy).forEach(key => {
                this.lazyComponents[key] = lazy[key];
            })
        });

        this.router.events
            .subscribe(async (e: any) => {

                let url: string = e && e.url ? e.url.split('?')[0] : null;
                if (e instanceof NavigationError) {
                    console.warn(e);
                    this.loading$.next(false);
                    this.pageLoading$.next(false);
                }
                if (e instanceof NavigationStart) {
                    this.nav$.next(true);
                    this.state$.next(url);
                    this.pageLoading$.next(true);
                    if (url !== this.path$.getValue()) {
                        this.loading$.next(true);
                        this.submenu$.next(null);
                    }
                } else {
                    if (e instanceof NavigationEnd && url !== this.path$.getValue()) {
                        await this.buildMenu(url);
                        this.loadPage(url, this.getLeafRoute(this.router.routerState.root).snapshot.params)
                            .then(async (result: any[]) => {

                                let page: Page = new Page(result[0]);

                                // TITLE & META tags.
                                let title: string = '';

                                await Promise.all(['meta', 'jsonld'].map((prop: string) => {

                                    if (page[prop]) {

                                        switch (prop) {
                                            case 'meta':

                                                Object.keys(page.meta).forEach((tagName: string) => {
                                                    if (tagName !== 'title') {
                                                        this.meta.updateTag({
                                                            name: tagName,
                                                            content: page.meta[tagName]
                                                        });
                                                    } else {
                                                        title = page.meta[tagName] || '';
                                                        if (title) {
                                                            this.titleService.setTitle(title);
                                                        }
                                                    }
                                                });

                                                break;
                                            // case 'content':
                                            //     Promise.all(['styles', 'sections'].map((subProp: string) => {
                                            //         if (page[prop][subProp]) {
                                            //             switch (subProp) {
                                            //                 case 'sections':
                                            //                     Promise.all(page.content.sections.map((section: any) => {
                                            //                         if (!section._style && section.preloadStyles) {
                                            //                             section._style = {};
                                            //                             section.preloadStyles.forEach((style: any) => {
                                            //                                 section._style[style.name] = style.value;
                                            //                             });
                                            //                         }
                                            //                     }));
                                            //                     break;
                                            //             }
                                            //         }
                                            //     }));
                                            //     break;
                                            case 'jsonld':
                                                // JSON-LD
                                                if (page.jsonld && Object.keys(page.jsonld) && Object.keys(page.jsonld).length) {
                                                    this.jsonld.next(page.jsonld);
                                                }
                                                break;
                                        }

                                    }

                                }));

                                // ADD CANONICAL LINK TAG?
                                // const link =
                                //   this.document.head.querySelector('link[rel="canonical"]') as HTMLLinkElement ||
                                //   this.document.head.appendChild(this.document.createElement('link'));
                                // if (link) {
                                //   link.rel = 'canonical';
                                //   link.href = `${environment.url}${page.url}`;
                                // }

                                this.page$.next(page);
                                this.pageNotFound$.next(!page?.id);
                                this.loading$.next(false);
                                this.pageLoading$.next(true);

                                if (!url.match(/login|logout/) && url !== '/' && !page.id) {
                                    this.lSvc.saveState('lastPage', url);
                                    this.lSvc.saveGState('lastPage', url);
                                }
                            });

                    } else {
                        this.loading$.next(false);
                    }
                }
            });

        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe(result => {
            this.dimensions$.next({
                mobile: (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]),
                tablet: (result.breakpoints[Breakpoints.Medium]),
                desktop: (result.breakpoints[Breakpoints.Large] || result.breakpoints[Breakpoints.XLarge])
            });
        });

    }

    async buildMenu(url?: string) {
        this.path$.next(url);
        let stack: IMenuItem[] = [];
        let iterate = (menu: IMenu) => {
            if (menu?.items) {
                menu.items.forEach((item, n) => {
                    menu.items[n].open = !!(url?.match(item.routerLink || item.pathMatch));
                    menu.items[n].active = (url === item.routerLink);
                    if (menu.items[n].active || menu.items[n].open) {
                        stack.push(item);
                    }
                    if (menu.items[n].items?.length) {
                        menu.items[n] = iterate(menu.items[n]);
                    }
                });
            }
            return menu;
        }
        let menu = iterate(await this.menuSvc.getMenu());
        this.menu$.next(menu);
        this.activeStack$.next(stack);
        this.roles$.next(this.findMenuItemRoles(menu, window.location.pathname) || []);
    }

    findMenuItemRoles(menu: IMenu, path: string) {
        let result: any;
        if (menu?.items) {
            for (let item of menu.items) {
                if (item.routerLink === path) {
                    result = item.roles;
                } else if (item.items) {
                    result = this.findMenuItemRoles(item, path);
                }
                if (result) {
                    break;
                }
            }
        }
        return result;
    }

    loadPage(url?: string, params?: any): Promise<any[]> {
        return new Promise(async resolve => {

            let path: string = (url) ? url :
                (window && window.location && window.location.pathname) ? window.location.pathname : '';

            let cleaned_path: boolean = false;
            if (path && params) {
                Object.keys(params).forEach((segment: string) => {
                    if (path.match(`/${params[segment]}`)) {
                        cleaned_path = true;
                    }
                    path = path.replace(`/${params[segment]}`, `/${segment}`);
                });
            }

            const fallback = async () => {
                try {
                    const pageData: any = await this.cSvc.callAPI(`/cms/page?path=${encodeURIComponent(path)}&site=${environment.pageServiceSiteId||''}&client_id=${this.cSvc.client_id}`, 'get');
                    resolve([pageData, path, cleaned_path]);
                } catch (err2) {
                    console.warn(err2);
                    resolve([{}, path, cleaned_path]);
                }
            };

            try {

                if (environment.type === 'prod') {
                    const pData: any = await this.http.get(`/${encodeURIComponent(path).replace(/%2F/g, '__')}.json`).toPromise()
                    resolve([pData, path, cleaned_path]);
                } else {
                    await fallback();
                }

            } catch (err) {
                await fallback();
            }
        });
    }

    private getLeafRoute(route: ActivatedRoute): ActivatedRoute {
        if (route === null) return null; //or throw ?
        while (route.firstChild) route = route.firstChild;
        return route;
    }

    scrollTop(view?: ElementRef | any) {
        try {
            setTimeout(() => {
                view.nativeElement.scrollIntoView({behavior: "smooth", block: "start"});
            }, 100);
        } catch (e) {
            console.warn(e);
        }
    }

    async playAudio(file?: string) {
        let audio = new Audio();
        audio.src = `/assets/sounds/${file || 'alert_simple.wav'}`;
        audio.load();
        await audio.play();
    }

    copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    rightClick(e: MouseEvent, i: IMenuItem[]) {
        e.preventDefault();
        this.modal$.next({
            component: RightClickMenu,
            styles: {'z-index': '35'},
            onLoaded: (comp: RightClickMenu) => {
                comp.event = e;
                comp.items = i;
                comp.init();
            }
        })
    }

    async triggerCron(path: string, data?: any, ignoreFeedback?: boolean) {
        this.loading$.next(true);
        try {

            data = data || {};
            data.path = path;

            let result: any = await this.cSvc.callAPI('/cron/trigger', 'post', data);
            if (result?.feedbackId && !ignoreFeedback) {
                this.monitorFeedback(result.feedbackId);
            } else {
                this.notification$.next({
                    title: 'Process Started!',
                    message: 'This may take a few minutes. You may need to refresh the page.'
                });
                return result?.feedbackId;
            }

        } catch (error) {
            this.alert$.next(error);
        }
        this.loading$.next(false);

    }
    
    monitorFeedback(feedbackId: string) {
        this.modal$.next({
            component: FeedbackItemsComponent,
            label: 'Progress Monitor',
            onLoaded: (comp: FeedbackItemsComponent) => {
                comp.feedbackId = feedbackId;
                comp.ngOnChanges();
            }
        });
    }

    hideBlock(ms?: number) {
        setTimeout(() => {
            this.blocking$.next(false);
        }, ms)
    }

    setState(url: string, data?: any) {
        if (url && !url.match(/\/login/)) {
            window.history.replaceState(data||{}, '', url);
            this.state$.next(url);
        }
    }

    handleClick(item: string|{ site: string, url: string }) {
        let url: string;
        if (item instanceof Object) {
            let sites: any[] = this.cSvc.c$.getValue()?.config?.sites || [];
            let site: any = sites.find(s => s.id === item.site);
            url = environment.type === 'prod' ? site.prod_url : site.url;
            url += item.url;
            console.log(1, url);
            window.open(url, '_self');
        } else {
            url = item;
            this.router.navigateByUrl(url);
        }
    }

    async callAPIWrapper(path: string, method: 'get' | 'post' | 'delete', data?: any, token?: any) {
        let result: any;
        this.blocking$.next(true);
        try {
            result = await this.cSvc.callAPI(path, method, data, token);
        } catch (e) {
            this.alert$.next(e);
        }
        this.blocking$.next(false);
        return result;
    }
}

export interface IModalOptions {
    component: any;
    onLoaded: Function;
    onSave?: Function;
    closeLabel?: string;
    buttons?: IMenuItem[];
    label?: string;
    styles?: any
}

export interface IButton {
    label?: string;
    click?: Function;
    class?: string;
}

export interface IAlert {
    id?: string;
    title?: string;
    message?: string;
    action?: string;
    value?: any;
    object?: any;
    html?: string;
    icon?: 'red' | 'green',
    buttons?: IMenuItem[];
    inputs?: any[], // decprecate?
    options?: IMenuItem[],
    maxWidth?: string,
    payload?: IFcmNotificationPayload
    timeout?: number;
    component?: any;
    onLoaded?: any;
    styles?:any;
}

export interface DataServiceAlert {
    title?: string;
    message?: string;
    background?: string;
    foreground?: string;
    width?: string;
    maxWidth?: string;
    inputs?: { type: string, name: string, label: string, value: any }[];
    options?: { label: string; click: (data?: any) => void }[];
    buttons?: { label: string; class?: string; click?: (data?: any) => void }[];
}

export interface IScreenDimensions {
    mobile?: boolean;
    tablet?: boolean;
    desktop?: boolean;
}
