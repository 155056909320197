"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RcrtQuote = exports.RcrtCart = exports.RcrtOrderItem = exports.RcrtOrder = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class RcrtOrder extends model_core_1.Order {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type || 'orders', idLen);
        this.email = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtOrder);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
}
exports.RcrtOrder = RcrtOrder;
class RcrtOrderItem extends model_core_1.OrderItem {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type || 'items', idLen);
        this.email = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtOrderItem);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
}
exports.RcrtOrderItem = RcrtOrderItem;
class RcrtCart extends RcrtOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtCart);
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new RcrtOrder(await super.clean(new RcrtOrder(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.RcrtCart = RcrtCart;
class RcrtQuote extends RcrtOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'quotes', 23);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtQuote);
    }
    toString() {
        return `Quote: ${this.quote_id}`;
    }
    async copy(parent) {
        return new RcrtQuote(await super.clean(new RcrtQuote(this), parent, ['items', 'shipments'], null, true));
    }
    async copyToOrder(parent) {
        await this.loadAll();
        return new RcrtOrder(await super.clean(new RcrtOrder(this), parent, ['items', 'shipments'], null, true));
    }
}
exports.RcrtQuote = RcrtQuote;
index_1.olm.orders = (ref, map) => {
    return new RcrtOrder(ref, map);
};
index_1.olm.quotes = (ref, map) => {
    return new RcrtQuote(ref, map);
};
index_1.olm.items = (ref, map) => {
    return new RcrtOrderItem(ref, map);
};
