import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IMenuItem} from '@nxt/model-core';

import {takeUntil} from 'rxjs/operators';

import {PageService} from '../../shared/_services/page.service';
import {PopButtonComponent} from '../../shared/buttons/pop-button.component';
import {IconsComponent} from '../../shared/icons/icons.component';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';

@Component({
    standalone: true,
    imports: [CommonModule, IconsComponent, PopButtonComponent],
    selector: 'footer-buttons-component',
    template: `
        <footer *ngIf="buttons?.length" class="footer w-full whitespace-nowrap {{footerBg}}" style="z-index:20">
            <ng-container *ngFor="let button of buttons">
                <ng-container *ngIf="!button.hide && (!button.show || button.show())">
                    <button *ngIf="!button.items"
                            (click)="handleClick(button)"
                            [disabled]="button.disabled"
                            [class]="'btn-sm '+(button.class||'btn-gray')">
                        <icon *ngIf="button.icon" [name]="button.icon?.name"
                              [class]="button.icon?.class||'h-3 w-3 mr-2'"></icon>
                        {{button.label}}
                    </button>
                    <pop-button *ngIf="button.items"
                                [label]="button.label"
                                [disabled]="button.disabled"
                                [iconName]="button.icon?.name"
                                [iconClass]="button.icon?.class"
                                [btnClass]="'btn-sm '+(button.class||'btn-gray')"
                                [items]="button.items"
                                menuPlacement="top-start"
                    ></pop-button>
                </ng-container>
            </ng-container>
        </footer>
    `
})
export class FooterButtonsComponent extends OnDestroyPage implements OnDestroy {
    @Output() onSaveClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() footerBg: string = 'bg-gray-300';
    @Input() buttons: IMenuItem[];

    constructor(
        private pSvc: PageService
    ) {
        super();
        this.pSvc.clickEsc$
            .pipe(takeUntil(this.d$))
            .subscribe(e => {
                if (e) {
                    this.onClose.emit();
                }
            });
    }

    handleClick(button: IMenuItem) {
        if (button.click instanceof Function) {
            button.click()
        } else if (button.closeOnClick) {
            this.onClose.emit();
        }
    }
}
