"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Device = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Device extends Base_1.Base {
    constructor(data, olMap) {
        super('devices', 9);
        this.label = '';
        this.type = '';
        this.os = '';
        this.subscription = null;
        this.failed = false;
        this.token = '';
        (0, Common_1.setObjectProperties)(this, data, olMap, Device);
    }
    async save(parent) {
        if (!this.id) {
            throw 'Cannot save device without id.';
        }
        return super.save(parent);
    }
}
exports.Device = Device;
index_1.olm.devices = (ref, map) => {
    return new Device(ref, map);
};
