import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {PageService} from '@library/shared/_services/page.service';
import {FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {format, parse, startOfDay} from 'date-fns';
import {InputDateCalendar} from './input-date-calendar';
import {take} from 'rxjs/operators';
import {OnDestroyPage} from '../_inherited/ondestroy.page';
import {InputDateTimeCalendar} from './input-date-time-calendar';
import {EDateFormats} from '@nxt/model-core';

@Component({
    selector: 'input-date',
    standalone: true,
    imports: [
        CommonModule, IconsComponent,
        FormsModule, ReactiveFormsModule, InputDateCalendar
    ],
    template: `
        <div #container *ngIf="form && controlName" [formGroup]="localForm"
             [class]="'w-full max-w-sm relative mt-4 bg-white border border-gray-300 rounded-md shadow-sm ' + class">
            <label *ngIf="label" [for]="controlName"
                   class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">{{label}}</label>
            <div class="flex px-3 py-2 relative">
                <input type="text"
                       [placeholder]="placeholder"
                       (dblclick)="showCalendar()"
                       [formControlName]="controlName"
                       [id]="controlName"
                       [class]="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required') ? errorInputClass : inputClass"
                />
                <button (click)="showCalendar()" class="btn-gray border-t-0 border-r-0 border-b-0 absolute -right-1 top-0 h-full">
                    <icon class="h-5 w-5" name="heroicon-outline-calendar"></icon>
                </button>
            </div>
            <p *ngIf="form?.get(controlName).touched && form.get(controlName)?.hasError('required')"
               class="px-3 pt-1 italic text-xs text-red-500">
                Required
            </p>
            <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('invalid')"
               class="px-3 pt-1 italic text-xs text-red-500">
                Required format: m/d/yyyy
            </p>
            <p *ngIf="form?.get(controlName)?.touched && form.get(controlName)?.hasError('min')"
               class="px-3 pt-1 italic text-xs text-red-500">
                Date not allowed
            </p>
        </div>
        <div [formGroup]="form">
            <input type="hidden"
                   [placeholder]="placeholder"
                   [formControlName]="controlName"
                   [id]="controlName"
                   class="h-0 w-0 hidden"
            />
        </div>
    `
})
export class InputDateComponent extends OnDestroyPage implements OnChanges {
    @ViewChild('container', {static: false}) container: ElementRef;
    @Output() onChange: EventEmitter<[Date,string]> = new EventEmitter<[Date,string]>();
    @Output() onDestroy: EventEmitter<Date> = new EventEmitter<Date>();
    @Input() class: string = '';
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() minDate: Date;
    @Input() minDateFormControlName: string;
    @Input() placeholder: string = '';
    @Input() zIndex: string = ''
    @Input() hours: string[];
    @Input() minutes: string[];
    localForm: UntypedFormGroup;

    @Input() includeDate: boolean = true;
    @Input() requireDate: boolean = false;
    @Input() includeTime: boolean = false;
    @Input() requireTime: boolean = false;

    baseInputClass: string = 'w-full block border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-xs';
    comp: InputDateCalendar;
    activeFormat: string;

    get errorInputClass() {
        return `input-error ${this.baseInputClass}`;
    }
    get inputClass() {
        return `input-default ${this.baseInputClass}`;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.onDestroy.emit();
    }

    constructor(
        public pSvc: PageService,
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnChanges(changes?: SimpleChanges) {
        this.activeFormat = this.includeTime ? EDateFormats.DATE_TIME : EDateFormats.DATE;
        this.placeholder = this.placeholder ? this.placeholder : this.activeFormat;

        if (this.requireTime && !this.includeTime) {
            console.warn(`You cannot leave includeTime false and requireTime true.  Time will not be required.`);
        }
        if (this.form && this.controlName && !this.localForm) {
            let data: any = {};
            let d: Date;
            if (this.form.get(this.controlName).value instanceof Date && this.form.get(this.controlName).value.toString() !== 'Invalid Date') {
                d = this.form.get(this.controlName).value;
            } else if (this.form.get(this.controlName).value && !isNaN(Number(this.form.get(this.controlName).value))) {
                d = new Date(this.form.get(this.controlName).value);
            }
            if (d instanceof Date && d.toString() === 'Invalid Date') {
                d = null;
            }

            this.checkForMinDate();
            if (this.minDate && (!d || d.valueOf() < this.minDate.valueOf())) {
                d = this.minDate;
            }
            data[this.controlName] = [d ? format(d, this.activeFormat) : '', [this.dateValidator(this.requireTime, this.minDate)]];
            this.localForm = this.fb.group(data);
        }
        // If the minDate changed, we need to reset the value (possibly);
        let d: Date = this.form.get(this.controlName).value;
        if (this.minDate && d && d.valueOf() < this.minDate.valueOf()) {
            d = this.minDate;
            this.setDate(d);
        }
    }

    checkForMinDate() {
        if (this.minDateFormControlName && this.form?.get(this.minDateFormControlName)?.value) {
            this.minDate = this.form?.get(this.minDateFormControlName).value;
        }
    }

    parseDate(value: string):[Date,string] {
        // Parser handles date-only or dateTime formats, based on what is required/optional
        // Start with whether time is included. If so, check against dateTime format.
        let activeFormat: string = this.includeTime ? EDateFormats.DATE_TIME : EDateFormats.DATE;
        let d: Date = parse(value, activeFormat, new Date());
        // If parse fails, maybe the user only input a date, so check date format.
        if (isNaN(d.valueOf()) && !this.requireTime) {
            activeFormat = EDateFormats.DATE;
            d = parse(value, EDateFormats.DATE, new Date());
        }
        return [d,activeFormat];
    }

    dateValidator(requireTime: boolean, minDate: Date) {
        return (control: FormControl) => {
            if (control.value) {
                let [d,activeFormat] = this.parseDate(control.value);
                if (isNaN(d.valueOf())) {
                    return { invalid: true };
                } else if (this.requireTime && minDate && minDate.valueOf() > d.valueOf()) {
                    return {min: true};
                } else if (!requireTime && startOfDay(minDate).valueOf() > startOfDay(d).valueOf()) {
                    return {min: true};
                } else if (
                    this.form?.get(this.controlName)?.value?.valueOf() !== d.valueOf()
                    && (!this.minDate || d.valueOf() > this.minDate.valueOf())
                    && d.getFullYear() > 1000
                ) {
                    this.activeFormat = activeFormat;
                    this.setDate(d, true);
                }
            }
            return null;
        }
    }

    setDate(d: Date, skipEmit?: boolean) {
        if (d && !isNaN(d.valueOf())) {
            this.form.get(this.controlName).setValue(d);
            this.localForm?.get(this.controlName)?.setValue(d ? format(d, this.activeFormat) : '');
            if (!skipEmit) {
                this.onChange.emit([d,this.activeFormat]);
            }
        }
    }

    show:boolean;
    showCalendar() {
        if (!this.show) {
            this.show = true;

            let component = this.includeTime ? InputDateTimeCalendar : InputDateCalendar;

            this.pSvc.modal$.next({
                component: component,
                onLoaded: (comp) => {

                    this.checkForMinDate();
                    comp.minDate = this.minDate;
                    comp.requireTime = this.requireTime;
                    comp.activeFormat = this.activeFormat;

                    let d: Date = this.form.get(this.controlName).value || this.minDate || new Date();
                    comp.date = format(d, this.includeTime ? EDateFormats.DATE_TIME : EDateFormats.DATE);

                    if (this.hours) {
                        comp.hours = this.hours;
                    }
                    if (this.minutes) {
                        comp.minutes = this.minutes;
                    }
                    comp.ngOnChanges();
                    comp.onDestroy.pipe(take(1)).subscribe(d => {
                        this.show = false;
                    })
                    comp.onClose.pipe(take(1)).subscribe((result: [Date,string]) => {
                        if (result?.length) {
                            let d: Date = result[0];
                            this.activeFormat = result[1];
                            this.setDate(d);
                        }
                    });
                }
            })
        }

    }
}
