import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

import {User, Contact, Client} from '@nxt/model-core';

import {ClientService} from '../_services/client.service';

import {UserService} from '../../nxt/_services/user.service';
// @ts-ignore
import {environment} from '@env/environment';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ImgFallbackDirective} from '../_directives/img-fallback';

@Component({
    selector: 'avatar',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        ImgFallbackDirective
    ],
    template: `
        <div class="flex-shrink-0 relative h-{{size}} w-{{size}} {{class}}">
            <img *ngIf="photo"
                 [style.visibility]="photo ? 'visible':'hidden'"
                 class="relative inline-block h-{{size}} w-{{size}} rounded-full"
                 [src]="photo" [alt]="name" [title]="name" fallback
            >
            <div *ngIf="!photo" [style.backgroundColor]="bgColor" [style.color]="color" [title]="name"
                 class="absolute top-0 left-0 inline-block table bg-light text-dark h-{{size}} w-{{size}} rounded-full text-center">
                <div class="table-cell align-middle uppercase {{textSize}}">{{ first }}{{ last }}</div>
            </div>
            <!-- Online Chicklet?: "bg-green-400", Offline: "bg-gray-300" -->
<!--            <span *ngIf="status"-->
<!--                  class="bg-{{status}}-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full "-->
<!--                  aria-hidden="true"></span>-->
        </div>

        <!-- force tailwind to include all the available avatar sizes -->
        <span class="h-6 h-7 h-8 h-9 h-10 h-11 h-12 h-14 h-16 w-6 w-7 w-8 w-9 w-10 w-11 w-12 w-14 w-16 md:col-span-1 sm:col-span-2 sm:grid-cols-2 sm:grid-cols-1 hidden"></span>
    `
})
export class AvatarComponent implements OnChanges {
    @Input() size: '6' | '7' | '8' | '9' | '10' | '11' | '12' | '14' | '16' = '6';
    @Input() debug: boolean;
    @Input() class: string = '';
    @Input() user: User | Contact | Client | any;
    name: string = '';

    get textSize(): string {
        if (Number(this.size) <= 6) {
            return 'text-xs';
        } else if (Number(this.size) <= 9) {
            return 'text-l';
        } else if (Number(this.size) <= 12) {
            return 'text-xl';
        } else {
            return 'text-2xl';
        }
    }

    u: User | Contact | Client | IAvatar;
    c: Client;
    bgColor: string;
    color: string;
    photo: string;
    first: string = '';
    last: string = '';

    constructor(
        private cSvc: ClientService,
        public uSvc: UserService
    ) {
    }

    async ngOnChanges(changes: SimpleChanges) {

        if (this.debug) {
            console.log(this.user);
        }

        if (this.user?.first || this.user?.last) {

            this.first = this.user.first||'';
            this.last = this.user.last||'';
            this.name = this.user.name||'';

        } else if (this.user) {

            this.name = this.user.name || this.user['displayName'] || '';
            if (this.user['color'] && this.user['contrast']) {

                this.first = (this.user.name || this.user['displayName'])?.substring(0, 1);
                this.last = '';
                this.bgColor = this.user['color'];
                this.color = this.user['contrast'];

            } else {

                let client_id = this.user._docRef?.path?.split('/')[1];
                this.c = client_id ? this.cSvc.clients$.getValue()?.find(i => i.id === client_id) : null;

                switch (this.user?._type) {
                    case 'clients':
                        this.photo = this.uSvc.images[this.cSvc.client_id][this.cSvc.client_id];
                        break;
                    case 'settings':
                        this.photo = this.user.img;
                        break;
                    default:
                        try {
                            this.photo = this.user.id ? this.uSvc.images && this.uSvc.images[this.cSvc.client_id] && this.uSvc.images[this.cSvc.client_id][this.user.id] : '';
                            this.first = (this.user['first_name'] || this.user.name)?.substring(0, 1);
                            if (this.user['last_name']) {
                                this.last = this.user['last_name']?.substring(0, 1) || '';
                            } else if (this.user?.name) {
                                let u: User = new User(this.user);
                                this.last = u.last_name?.substring(0, 1) || '';
                            }
                            if (this.debug) {
                                console.log(this.first, this.last);
                                console.log(this.photo);
                            }
                        } catch (e) {
                            console.warn(e);
                        }
                        break;
                }
            }
        }

        this.loadPhoto(this.user?.id);
    }

    async loadPhoto(id) {
        if (id) {
            if (this.uSvc.images[id]) {
                this.photo = this.uSvc.images[id];
            } else if (this.uSvc.images[id] !== false) {

                const img = new Image();
                img.onload = () => {
                    this.photo = img.src;
                    this.uSvc.images[id] = img.src;
                };
                img.onerror = () => {
                    this.photo = '';
                    this.uSvc.images[id] = false;
                };
                img.src = (environment.type==='local') ? `http://localhost:9199/v0/b/${environment.nxtProjectId}.appspot.com/o/users%2F${id}.jpg?alt=media&d=${this.uSvc.images.date}` : ` https://firebasestorage.googleapis.com/v0/b/${environment.nxtProjectId}.appspot.com/o/users%2F${id}.jpg?alt=media&d=${this.uSvc.images.date}`;

            }
        }
    }

}

export interface IAvatar {
    color: string,
    contrast: string,
    name: string,
    img?: string,
    _type?: string,
    id?: string
}
