"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Packaging = void 0;
const Dimension_1 = require("./Dimension");
const Common_1 = require("./Common");
const index_1 = require("../index");
class Packaging extends index_1.Base {
    constructor(data, olMap) {
        super('packaging', 12);
        this.id = '';
        this.name = '';
        this.dimensions = new Dimension_1.DimensionArray();
        this.cost = null;
        this.quantity = null;
        this.weight = null;
        this.manual = false;
        this.nmfc_class = '';
        this.ltl = false;
        this.handlingUnit = ''; // Used for GMTL to override the handling unit from Pallet to Other.
        this._m = ['dimensions', 'quantity', 'manual', 'nmfc_class', 'weight', 'ltl'];
        (0, Common_1.setObjectProperties)(this, data, olMap || index_1.olm, Packaging);
    }
}
exports.Packaging = Packaging;
index_1.olm.packaging = (ref, map) => {
    return new Packaging(ref, map);
};
