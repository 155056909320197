import {Injectable} from '@angular/core';

// @ts-ignore
import {environment} from '@env/environment';
import {getMessaging, getToken, Messaging, onMessage} from '@angular/fire/messaging';
import {getApp} from '@angular/fire/app';
import {RcrtDataService} from './rcrt-data.service';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {RcrtUser} from '@nxt/model-rcrt';
import {firstValueFrom, Subscription} from 'rxjs';
import {PageService} from '@library/shared/_services/page.service';
import {Router} from '@angular/router';

@Injectable()
export class RcrtPushService {
    messaging: Messaging;

    get token() {
        return this.lSvc.localState['pushToken'];
    }
    set token(t) {
        this.lSvc.saveState('pushToken', t);
    }

    get permission() {
        return this.lSvc.localState['pushTokenPermission'];
    }
    set permission(t) {
        this.lSvc.saveState('pushTokenPermission', t);
    }

    constructor(
        private rdSvc: RcrtDataService,
        private pSvc: PageService,
        private lSvc: LocalStorageService,
        private router: Router
    ) {

        this.messaging = getMessaging(getApp(environment.appName));
        firstValueFrom(this.rdSvc.user$)
            .then(user => {
                if (user?._exists) {
                    if (user?.metadata?.tokens?.length) {
                        if (this.permission==='denied' || !this.permission || (this.token && !user.metadata.tokens.includes(this.token))) {
                            this.disable();
                        } else if (this.permission) {
                            this.loadToken();
                        }
                    } else if (this.permission) {
                        this.loadToken();
                    }

                }
            });

    }

    async loadToken() {

        getToken(this.messaging, {vapidKey: environment.push_public_key})
            .then(async (currentToken) => {
                if (currentToken) {
                    this.token = currentToken;
                    let user: RcrtUser = this.rdSvc.user$.getValue();
                    if (user?._exists) {
                        user.metadata.tokens = user.metadata.tokens || [];
                        if (!user.metadata.tokens.includes(currentToken)) {
                            user.metadata.tokens.push(currentToken);
                            await this.rdSvc.updateUser(user, {metadata: user.metadata});
                        }
                    }
                } else {
                    this.disable();
                }
            })
            .catch((err) => {
                this.disable();
            });
    }

    async requestPermission() {
        if (!Notification?.requestPermission) {
            this.pSvc.notification$.next({
                title: 'iOS User?',
                message: `To turn on notifications, add this site to your home screen and then try again. Adding Recruit Messenger to your home screen makes it easier to get to your account, and also allows notifications to work.`
            })
        } else {
            Notification.requestPermission()
                .then(async (permission) => {
                    this.permission = permission;
                    if (permission === 'granted') {
                        this.loadToken();
                    } else if (this.token) {
                        this.disable();
                    } else if (permission === 'denied') {
                        this.pSvc.notification$.next({
                            title: 'Cannot Activate Notifications!',
                            message: `You have previously denied notifications. Please enable notifications on your browser for this page and try again.`
                        });
                    }
                })
        }
    }

    async disable() {
        this.permission = '';
        if (this.token) {
            let user: RcrtUser = this.rdSvc.user$.getValue();
            let i: number = (user?.metadata?.tokens||[]).findIndex(t => t === this.token);
            if (i > -1) {
                user.metadata.tokens.splice(i,1);
                await this.rdSvc.updateUser(user, {metadata: user.metadata});
            }
            this.token = '';
        }
    }
}
