"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupSubscription = exports.Group = exports.EThirdPartyEmailServices = exports.EContactGroupType = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
var EContactGroupType;
(function (EContactGroupType) {
    EContactGroupType["AUTO"] = "auto";
    EContactGroupType["MANUAL"] = "manual";
})(EContactGroupType || (exports.EContactGroupType = EContactGroupType = {}));
var EThirdPartyEmailServices;
(function (EThirdPartyEmailServices) {
    EThirdPartyEmailServices["NONE"] = "";
    EThirdPartyEmailServices["DRIP"] = "drip";
})(EThirdPartyEmailServices || (exports.EThirdPartyEmailServices = EThirdPartyEmailServices = {}));
class Group extends Base_1.Base {
    constructor(data, olMap) {
        super('groups', 13);
        this.name = '';
        this.default = false;
        this.type = EContactGroupType.MANUAL;
        this.medium = index_1.EMedium.EMAIL;
        this.subscribed = null;
        this.unsubscribed = null;
        this.third_party_id = '';
        this.third_party_tags = '';
        this.third_party_service = EThirdPartyEmailServices.NONE; // e.g. drip
        this.agents = [];
        (0, Common_1.setObjectProperties)(this, data, olMap, Group);
    }
    toString() {
        return `Group: ${this.name}`;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        ['name'].forEach(p => {
            r[p] = this[p] || null;
        });
        return r;
    }
}
exports.Group = Group;
class GroupSubscription extends Base_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'subscriptions', idLen || 9);
        this.contact = null;
        this.preferences = null;
        this._m = ['contact'];
        this._s = ['active', 'contact', 'last_date'];
        (0, Common_1.setObjectProperties)(this, data, olMap, GroupSubscription);
    }
    toString() {
        var _a;
        return `Subscription: ${(_a = this.contact) === null || _a === void 0 ? void 0 : _a.name}`;
    }
    save(parent) {
        var _a;
        if (!((_a = this.contact) === null || _a === void 0 ? void 0 : _a._docRef)) {
            throw `Cannot save subscription without a contact.`;
        }
        if (!this.id) {
            this.id = this.contact.id;
        }
        return super.save(parent);
    }
    async toSearchJSON(opts) {
        this.last_date = Date.now();
        return super.toSearchJSON(opts);
    }
}
exports.GroupSubscription = GroupSubscription;
index_1.olm.groups = (ref, map) => {
    return new Group(ref, map);
};
index_1.olm.subscriptions = (ref, map) => {
    return new GroupSubscription(ref, map);
};
