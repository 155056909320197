import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {ClientService} from '@library/shared/_services/client.service';
import {environment} from '@env/environment';
import {olm, User as RcrtUser} from '@nxt/model-rcrt';
import {RcrtDataService} from './rcrt-data.service';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {user as authUser} from '@angular/fire/auth';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable()
export class RcrtClientGuard {
    sub: Subscription;
    check: Subscription;

    constructor(
        private cSvc: ClientService,
        private rdSvc: RcrtDataService,
        private fSvc: ConsumerFireService,
        private aSvc: ConsumerAccountService
    ) {

  }

  watchUser(id: string) {
      this.sub?.unsubscribe();
      this.sub = this.fSvc.watchDoc(`users/${id}`, null, true)
          .subscribe(async res => {
                  let u: RcrtUser = new RcrtUser( res, olm );
                  if (u?._exists) {
                      this.rdSvc.user$.next(u);
                  } else {
                      this.sub.unsubscribe();
                      this.rdSvc.user$.next(null);
                  }
              },
              e => {
                  this.sub.unsubscribe();
                  this.rdSvc.user$.next(null);
                  console.error(e);
              },
              () => {
                  console.log('stop watching user');
              }
          );
  }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise((resolve) => {
            this.cSvc.c$.next(environment.default_client);
            authUser(this.aSvc.auth)
                .subscribe(
                async (u: any) => {
                    if (!u) {
                        await this.aSvc.signInAnon();
                    } else {
                        this.watchUser(u.uid);
                        this.check = this.rdSvc.user$.subscribe(
                            u => {
                                if (u !== undefined) {
                                    this.check.unsubscribe();
                                    if (u?._exists && (!u?.last_date || (Date.now() - u?.last_date) > 86400000)) {
                                        this.rdSvc.updateUser(u, {last_date:Date.now()});
                                    }
                                    resolve(true);
                                }
                            }
                        )

                    }
                },
                async (err: any) => {
                    console.warn(err);
                    resolve(false);
                }
            );

        });
    }

}
