"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Activity = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Activity extends model_core_1.Base {
    constructor(data, olMap) {
        super('activity', 12);
        this.action = null;
        this.ref = null;
        this.type = '';
        this.object = null;
        this.user_id = '';
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Activity);
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        if (this.ref) {
            if (opts === null || opts === void 0 ? void 0 : opts.getDoc) {
                this.object = (0, model_core_1.loadObject)(await opts.getDoc(this.ref), opts);
            }
            else if ((_a = this.ref) === null || _a === void 0 ? void 0 : _a.get) {
                this.object = (0, model_core_1.loadObject)(await this.ref.get(), opts);
            }
        }
    }
    toJSON() {
        let r = super.toJSON();
        if (!this.ref) {
            throw `Cannot save Activity without a ref!`;
        }
        ['active', 'object', 'last_date'].forEach(p => {
            delete r[p];
        });
        return r;
    }
}
exports.Activity = Activity;
index_1.olm.activity = (ref, map) => {
    return new Activity(ref, map);
};
