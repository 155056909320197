"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Refund = exports.Payment = exports.PAYMENT_PROVIDERS = exports.PAYMENT_TYPES_MAP = exports.PAYMENT_TYPES = void 0;
const Common_1 = require("./Common");
const index_1 = require("../index");
var PAYMENT_TYPES;
(function (PAYMENT_TYPES) {
    PAYMENT_TYPES["CARD"] = "card";
    PAYMENT_TYPES["CASH"] = "cash";
    PAYMENT_TYPES["WIRE"] = "wire";
    PAYMENT_TYPES["PAYPAL"] = "paypal";
    PAYMENT_TYPES["MANUAL"] = "manual";
    PAYMENT_TYPES["STRIPE_LINK"] = "link";
    PAYMENT_TYPES["NONE"] = "";
})(PAYMENT_TYPES || (exports.PAYMENT_TYPES = PAYMENT_TYPES = {}));
exports.PAYMENT_TYPES_MAP = {
    'card': 'CC',
    'cash': '$',
    'wire': 'W',
    'paypal': 'PP',
    'manual': 'M'
};
var PAYMENT_PROVIDERS;
(function (PAYMENT_PROVIDERS) {
    PAYMENT_PROVIDERS["STRIPE"] = "stripe";
    PAYMENT_PROVIDERS["BRAINTREE"] = "braintree";
    PAYMENT_PROVIDERS["PAYPAL"] = "paypal";
    PAYMENT_PROVIDERS["NONE"] = "";
})(PAYMENT_PROVIDERS || (exports.PAYMENT_PROVIDERS = PAYMENT_PROVIDERS = {}));
class Payment extends index_1.Base {
    get name() {
        var _a, _b, _c, _d, _e;
        switch (this.type) {
            case PAYMENT_TYPES.PAYPAL:
                return 'Paypal';
            case PAYMENT_TYPES.WIRE:
                return 'Wire Transfer';
            case PAYMENT_TYPES.CARD:
                if (((_a = this.card) === null || _a === void 0 ? void 0 : _a.brand) && ((_b = this.card) === null || _b === void 0 ? void 0 : _b.last4)) {
                    return `${this.card.brand} *${this.card.last4}`;
                }
                else {
                    return `Credit Card`;
                }
            case PAYMENT_TYPES.MANUAL:
                return 'Manual';
            case PAYMENT_TYPES.CASH:
                return 'Cash';
            case PAYMENT_TYPES.STRIPE_LINK:
                if (((_d = (_c = this.intent) === null || _c === void 0 ? void 0 : _c.payment_method_types) === null || _d === void 0 ? void 0 : _d.length) === 1) {
                    switch ((_e = this.intent) === null || _e === void 0 ? void 0 : _e.payment_method_types[0]) {
                        case 'us_bank_account':
                            return 'Wire Transfer';
                        case 'card':
                            return 'Credit Card';
                    }
                }
                return 'Stripe Link';
        }
    }
    constructor(data, olMap) {
        super('payments', 23);
        this.user_id = ''; // Deprecate?
        this.agent_id = ''; // Deprecate?
        this.order_id = ''; // Deprecate?
        this.paid = null;
        this.date_paid = null;
        this.type = PAYMENT_TYPES.NONE;
        this.provider = PAYMENT_PROVIDERS.NONE;
        this.billing = {}; // Deprecate?
        this.contact_id = '';
        this.amount = null;
        this.result = {};
        this.preauth = {};
        this.paypal = {};
        this.notes = '';
        this.card_id = ''; // Deprecate (or convert to getter)?
        this.card = null;
        this.intent = null;
        // intents?: ChildArray = new ChildArray();
        this._m = ['id', 'type', 'provider', 'amount', 'card', 'intent'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Payment);
    }
    toString() {
        return ['type', 'provider', 'amount'].reduce((r, p) => {
            if (this[p]) {
                r.push(this[p]);
            }
            return r;
        }, []).join(', ');
    }
}
exports.Payment = Payment;
class Refund extends Payment {
    constructor(data, olMap) {
        super('refunds', 22);
        (0, Common_1.setObjectProperties)(this, data, olMap, Refund);
    }
    toString() {
        return `Refund: ${this.amount}`;
    }
}
exports.Refund = Refund;
index_1.olm.payments = (ref, map) => {
    return new Payment(ref, map);
};
index_1.olm.refunds = (ref, map) => {
    return new Refund(ref, map);
};
