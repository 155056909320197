"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unread = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Unread extends Base_1.Base {
    constructor(data, olMap) {
        super('unread', 14);
        this.ref = null;
        this.seen = false;
        (0, Common_1.setObjectProperties)(this, data, olMap, Unread);
    }
}
exports.Unread = Unread;
index_1.olm.unread = (ref, map) => {
    return new Unread(ref, map);
};
