"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlgoliaQueryBuilder = exports.AlgoliaQueryFacetFilterItem = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
class AlgoliaQueryFacetFilterItem extends Base_1.Base {
    constructor(data) {
        super('', null);
        this.id = '';
        this.name = '';
        this.key = '';
        this.key_unique = false;
        this.value = null;
        this.more = null;
        this.color = '';
        this.contrast = '';
        this.icon = '';
        this.type = '';
        (0, Common_1.setObjectProperties)(this, data);
    }
    toJSON() {
        return Object.assign({}, this);
    }
    toMinJSON() {
        return this.toJSON();
    }
    toFullJSON() {
        return this.toJSON();
    }
}
exports.AlgoliaQueryFacetFilterItem = AlgoliaQueryFacetFilterItem;
class AlgoliaQueryBuilder extends Base_1.Base {
    constructor(data, type) {
        var _a;
        super(type, null);
        this.query = '';
        this.typoTolerance = '';
        this.facetFilters = [];
        this.hitsPerPage = 100;
        this.numericFilters = [];
        this.page = 0;
        this.type = ''; // class being searched (e.g. contacts, threads, orders, etc)
        this.filterItems = [];
        this.and = false;
        this.aroundLatLng = '';
        this.aroundRadius = null;
        (0, Common_1.setObjectProperties)(this, data);
        this.filterItems = (_a = this.filterItems) === null || _a === void 0 ? void 0 : _a.map(i => new AlgoliaQueryFacetFilterItem(i));
    }
    addFilter(item) {
        if (item.key_unique) {
            // Remove any other items with the same key, because key_unique means there can
            // be only one filter with that key (e.g. last_date);
            for (let i of this.filterItems) {
                if (i.key === item.key) {
                    this.remove('filterItems', i);
                }
            }
        }
        // Now add the filter.
        this.add('filterItems', item);
        this.build();
    }
    removeFilter(item) {
        this.remove('filterItems', item);
        this.build();
    }
    get namedFilterItems() {
        var _a;
        return ((_a = this.filterItems) === null || _a === void 0 ? void 0 : _a.reduce((items, i) => {
            if (i.name) {
                items.push(i);
            }
            return items;
        }, [])) || [];
    }
    toSearchRequest() {
        this.build();
        let filters = '';
        // Build the AND filters, if any.
        let ands = this.filterItems.reduce((a, b) => {
            if (b.type === 'and' || (b.type === 'or' && this.and)) {
                a.push(b.value);
            }
            return a;
        }, []);
        let ors = this.filterItems.reduce((a, b) => {
            if (b.type === 'or' && !this.and) {
                a.push(b.value);
            }
            return a;
        }, []);
        if (ands.length && ors.length) {
            filters = `((${ands.join(' AND ')}) AND (${ors.join(' OR ')}))`;
        }
        else if (ands.length) {
            filters = `(${ands.join(' AND ')})`;
        }
        else if (ors.length) {
            filters = `(${ors.join(' OR ')})`;
        }
        let r = {
            query: this.query,
            params: {
                facetFilters: this.facetFilters,
                numericFilters: this.numericFilters,
                filters: filters,
                page: this.page,
                hitsPerPage: this.hitsPerPage
            }
        };
        if (this.typoTolerance) {
            r.typoTolerance = this.typoTolerance;
        }
        if (this.aroundLatLng) {
            r.params.aroundLatLng = this.aroundLatLng;
        }
        if (this.aroundRadius) {
            r.params.aroundRadius = this.aroundRadius;
        }
        // console.log(r.params);
        return r;
    }
    build() {
        this.facetFilters = [];
        this.numericFilters = [];
        if (this.type) {
            this.facetFilters.push([`_type:${this.type}`]);
        }
        let groups = {};
        for (let i of this.filterItems) {
            if (i.key && !i.key_unique) {
                if (!groups[i.key]) {
                    groups[i.key] = { type: i.type, value: [] };
                }
                groups[i.key].value.push(i.value);
                if (i.more) {
                    groups[i.key].value.push(i.more);
                }
            }
            else {
                switch (i.type) {
                    case 'numeric':
                        this.numericFilters.push(i.value);
                        if (i.more) {
                            this.numericFilters.push(i.more);
                        }
                        break;
                    case 'facet':
                        this.facetFilters.push(i.value);
                        if (i.more) {
                            this.facetFilters.push(i.more);
                        }
                        break;
                }
            }
        }
        for (let key of Object.keys(groups)) {
            switch (groups[key].type) {
                case 'numeric':
                    this.numericFilters.push(groups[key].value);
                    break;
                case 'facet':
                    this.facetFilters.push(groups[key].value);
                    break;
            }
        }
    }
    async search(indexName, environment, algoliasearch) {
        var _a, _b, _c, _d;
        if (((_a = environment === null || environment === void 0 ? void 0 : environment.algolia) === null || _a === void 0 ? void 0 : _a.appId) && ((_b = environment === null || environment === void 0 ? void 0 : environment.algolia) === null || _b === void 0 ? void 0 : _b.searchKey)) {
            const aClient = algoliasearch((_c = environment === null || environment === void 0 ? void 0 : environment.algolia) === null || _c === void 0 ? void 0 : _c.appId, (_d = environment === null || environment === void 0 ? void 0 : environment.algolia) === null || _d === void 0 ? void 0 : _d.searchKey);
            const index = aClient.initIndex(indexName);
            let req = Object.assign({}, this.toSearchRequest());
            req.params.facetFilters.push([`_env:${environment.type}`]);
            let results = await index.search(req.query, req.params);
            if (results === null || results === void 0 ? void 0 : results.hits) {
                results.hits
                    .sort((a, b) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    let aC = ((_c = (_b = (_a = a['_highlightResult']) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.matchLevel) === 'full';
                    let bC = ((_f = (_e = (_d = b['_highlightResult']) === null || _d === void 0 ? void 0 : _d.city) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.matchLevel) === 'full';
                    let aA = ((_h = (_g = a['_highlightResult']) === null || _g === void 0 ? void 0 : _g.name) === null || _h === void 0 ? void 0 : _h.matchLevel) === 'full';
                    let bA = ((_k = (_j = b['_highlightResult']) === null || _j === void 0 ? void 0 : _j.name) === null || _k === void 0 ? void 0 : _k.matchLevel) === 'full';
                    let aI = (_l = a.icao) === null || _l === void 0 ? void 0 : _l.match(/^K/);
                    let bI = (_m = b.icao) === null || _m === void 0 ? void 0 : _m.match(/^K/);
                    return (aC && aA && aI && bC && bA && bI) ? 0 : (aC && aA && aI) ? -1 : (aC && aI) ? -1 : 1;
                });
            }
            return results;
        }
        else {
            console.warn('SEARCH SETTINGS NOT CONFIGURED');
        }
    }
    toJSON() {
        var _a;
        return { query: this.query || '', filterItems: ((_a = this.filterItems) === null || _a === void 0 ? void 0 : _a.map(i => i.toJSON ? i.toJSON() : i)) || [] };
    }
    toMinJSON(ignoreDocRef) {
        return this.toJSON();
    }
    toFullJSON() {
        return this.toJSON();
    }
}
exports.AlgoliaQueryBuilder = AlgoliaQueryBuilder;
