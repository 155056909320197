import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    Activity,
    CollegeRef, Comment,
    Email,
    ERcrtActions,
    olm,
    RcrtEventRef,
    RcrtShare,
    User as RcrtUser
} from '@nxt/model-rcrt';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {ConsumerScrollableList} from '@library/consumer/list/consumer-scrollable-list';
import {RcrtRatingsAvatar} from '../../account/_components/ratings/rcrt-ratings-avatar';
import {RcrtResultsMetrics} from '../rcrt-results-metrics';
import {PageService} from '@library/shared/_services/page.service';
import {ConsumerFireService, IFirestoreQuery} from '@library/consumer/_services/consumer.fire.service';
import {Router, RouterLink} from '@angular/router';
import {RcrtDataService} from '../../_services/rcrt-data.service';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'rcrt-activity-dropdown',
    standalone: true,
    imports: [
        CommonModule, IconsComponent, ConsumerScrollableList,
        RcrtRatingsAvatar, RcrtResultsMetrics, PipesModule, RouterLink
    ],
    template: `
        <div class="relative" *ngIf="player?.id">
            <button (click)="toggleMenu()" class="btn-clear btn-sm">
                <icon name="custom-notifications-active" class="h-8 w-8 {{ rdSvc.unread ? 'text-red-600' : 'text-white' }}"></icon>
            </button>

            <div *ngIf="show$|async" class="{{ menuState }} absolute -right-14 z-10 mt-5 flex w-screen max-w-max px-4">
                <div class="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div class="bg-white border-b border-gray-200 p-1 pl-4">
                        <div class="flex justify-between">
                            <rcrt-results-metrics *ngIf="rdSvc.player$|async" [parent]="rdSvc.player$|async"></rcrt-results-metrics>
                            <div class="font-bold text-lg" *ngIf="!(rdSvc.player$|async)">
                                Track Your Results!
                            </div>
                            <div>
                                <button class="btn-clear btn-xs" (click)="toggleMenu()">
                                    <icon name="heroicon-outline-x" class="h-6 w-6"></icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="max-h-200 overflow-y-auto">

                        <consumer-scrollable-list
                                #scrollableList
                                [itemTemplate]="itemTemplate"
                                [path]="path"
                                [hideEmpty]="true"
                                [baseQuery]="query"
                                [pageSize]="50"
                                [loadAllOpts]="opts"
                                (onLoaded)="handleResults()"
                        ></consumer-scrollable-list>
                        
                        <div class="italic p-4 text-sm text-gray-400">
                            Account activity, such as new views, clicks, email/share creations, etc are tracked and listed here.
                            <span *ngIf="rdSvc.player$|async"> Activity older than 60 days is removed.</span>
                        </div>
                    </div>
                    <div class="bg-black p-1">
                        <button class="btn-clear btn-sm text-accent hover:text-white" (click)="rdSvc.markAsRead();hide();">
                            Mark Activity Read
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <li class="rcrt-list-item flex border-b border-gray-200" (click)="handleClick(item['_url'], item)">
                <div class="w-full flex justify-between p-1">
                    <div class="flex space-x-3">
                        <div class="flex shrink-0 -space-x-1">
                            <img class="h-8 w-8 bg-gray-100 rounded-md ring-1 ring-white" [src]="item['_src']||'/assets/empty.png'" alt="">
                            <div *ngIf="item['_unread']" class="bg-red-700 h-3 w-3 text-white text-xxs flex place-content-center rounded-md ml-1">&nbsp;</div>
                        </div>
                        <div class="flex flex-col">
                            <div class="text-sm font-semibold text-gray-900">
                                {{ item['_label'] }}
                            </div>
                            <div class="text-xs text-gray-500">
                                {{ item['_description'] }}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col text-right text-xxs italic text-gray-500 uppercase whitespace-nowrap">
                        <div>{{ item.date | dateAgo }}</div>
                    </div>
                </div>
            </li>
        </ng-template>
    `
})
export class RcrtActivityDropdown extends OnDestroyPage {
    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @ViewChild('scrollableList', {static: false}) scrollableList: ConsumerScrollableList;
    @Input() label: string;
    @Input() player: RcrtUser;
    show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    placement: 'top-right' = 'top-right';
    menuState: string = 'opacity-0 -translate-y-1';
    show: boolean;
    opts: any;
    path: string;
    query: IFirestoreQuery[];

    constructor(
        public pSvc: PageService,
        public rdSvc: RcrtDataService,
        private fSvc: ConsumerFireService,
        private router: Router,
        private elRef: ElementRef
    ) {
        super();

        this.opts = this.fSvc.loadAllOpts(olm);

        this.pSvc.clickEsc$
            .pipe(takeUntil(this.d$))
            .subscribe(e => {
                if (e && this.show) {
                    this.show = false;
                    this.showHideMenu();
                }
            });
        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (this.show
                        && e?.target
                        && this.elRef?.nativeElement
                        && this.elRef.nativeElement.outerHTML.indexOf(e.target['outerHTML']) === -1
                        && (e.target as HTMLElement).id !== "anchor"
                        && (e.target as HTMLElement).id !== "chevron"
                        && (e.target as HTMLElement).nodeName !== "path"
                        && (e.target as HTMLElement).nodeName !== "svg"
                    ) {
                        this.show = false;
                        this.showHideMenu();
                    }
                }
            );

    }

    toggleMenu() {
        this.show = !this.show;
        this.showHideMenu();
        if (this.show && this.player) {
            this.path = `users/${this.player.id}/activity`;
            this.query = [{ name: 'orderBy', args: ['date','desc'] }];
            this.scrollableList?.loadData();
        }
    }

    async handleResults() {
        let opts: any = this.fSvc.loadAllOpts(olm);
        await Promise.all(this.scrollableList?.items?.map(async (item: Activity, i: number) => {

            // @ts-ignore
            await item.loadAll(opts);
            if (item.object?._exists) {
                await item.object.loadAll(opts);
            }
            item['_unread'] = (item.date > this.rdSvc.last_check && item.user_id !== this.rdSvc.user$.getValue()?.id);

            let cRef: CollegeRef;
            let eRef: RcrtEventRef;
            if (item.object instanceof Email) {
                cRef = item.object.cRef;
                eRef = item.object.eRef;
            } else if (item.object instanceof CollegeRef) {
                cRef = item.object;
            } else if (item.object instanceof RcrtEventRef) {
                eRef = item.object;
                await eRef.loadAll(opts);
            } else if (item.object instanceof Comment) {
                eRef = item.object.eRef;
                cRef = item.object.cRef;
            }
            // if (eRef) {
            //     await eRef.loadAll(opts);
            // }

            switch (item.action as ERcrtActions) {
                case ERcrtActions.COMMENT:
                    // @ts-ignore
                    let c: Comment = item.object as Comment;
                    item['_label'] = 'Comment Added';
                    item['_url'] = `/account/activity`;
                    if (cRef || eRef) {
                        item['_label'] = 'Note Added';
                        item['_url'] = eRef ? `/account/events/${eRef.id}` : `/account/colleges/${cRef?.id}`;
                    }
                    if (c?.creator?.first_name) {
                        item['_label'] = `${c.creator.first_name} Commented`;
                        if (cRef || eRef) {
                            item['_label'] = `${c.creator.first_name} Added a Note`;
                        }
                        item['_src'] = c.creator?.image?.src;
                        item['_description'] = c?.message;
                    }
                    break;
                case ERcrtActions.SHARE:
                    item['_label'] = eRef ? `Event Share Created` : 'Player Share Created';
                    item['_src'] = eRef ? eRef.event?.image?.src : this.player?.image?.src;
                    item['_description'] = eRef ? eRef?.event?.title : this.player?.name;
                    item['_url'] = eRef ? `/account/events/${eRef.id}` : `/account/tab/share`;
                    break;
                case ERcrtActions.REPLIES:
                    item['_label'] = `College Coach Replied`;
                    item['_src'] = eRef ? `${eRef.event?.image?.src}` : `${cRef?.college?.image?.src}`;
                    item['_description'] = eRef ? `${eRef.event?.title}/${cRef?.college?.title}` : `${cRef?.college?.title}`;
                    item['_url'] = eRef ? `/account/colleges/${cRef?.id}/event/${eRef.id}` : `/account/colleges/${cRef?.id}`;
                    break;
                case ERcrtActions.EMAIL:
                    item['_label'] = eRef ? `Event Email` : `College Email`;
                    item['_label'] += (item.object['from']) ? ' Received!' : ' Drafted';
                    item['_src'] = eRef ? `${eRef.event?.image?.src}` : `${cRef?.college?.image?.src}`;
                    item['_description'] = cRef?.college?.title;
                    item['_description'] =  eRef ? `${eRef.event?.title}/${cRef?.college?.title}` : `${cRef?.college?.title}`;
                    item['_url'] = eRef ? `/account/colleges/${cRef?.id}/event/${eRef?.id}` : `/account/colleges/${cRef?.id}`;
                    break;
                case ERcrtActions.VIEW:

                    if (item.object instanceof Email) {
                        item['_label'] = eRef ? 'Event Email Viewed' : `College Email Viewed`;
                        item['_src'] = eRef ? `${eRef.event?.image?.src}` : `${cRef?.college?.image?.src}`;
                        item['_description'] = eRef ? `${eRef.event?.title}/${cRef?.college?.title}` : `${cRef?.college?.title}`;
                        item['_url'] = eRef ? `/account/colleges/${cRef?.id}/event/${eRef.id}` : `/account/colleges/${cRef?.id}`;
                    } else if (item.object instanceof RcrtShare) {
                        item['_label'] = eRef ?  `Event Share Viewed` : `User Share Viewed`;
                        item['_src'] = eRef ? eRef?.event?.image?.src : this.player?.image?.src;
                        item['_description'] = eRef ? `${eRef?.event?.title}` : this.player?.name;
                        item['_url'] = eRef ? `/account/event/${eRef.id}/tab/share` : `/account/tab/share`;
                    }

                    break;
            }

        }));
    }

    async handleClick(path: string, item?: any) {
        console.log('item', item);
        if (path) {
            this.hide();
            await this.router.navigateByUrl(path);
        } else {
            this.pSvc.notification$.next({
                title: 'Oops!',
                message: `Couldn't find the related item!`
            });
        }
    }

    hide() {
        this.show = false;
        this.showHideMenu();
    }

    showHideMenu() {
        switch (this.show) {
            case true:
                this.show$.next(true);
                setTimeout(() => {
                    this.menuState = 'opacity-100 translate-y-0 transition ease-out duration-200';
                }, 200);
                this.onShow.emit();
                break;
            default:
                this.menuState = 'opacity-0 -translate-y-1 transition ease-in duration-150';
                setTimeout(() => {
                    this.show$.next(false);
                }, 200);
                break;
        }
    }

}
