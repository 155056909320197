import {Component, Input} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';

import {SpinnerComponent} from '../spinner/spinner.component';
import {BehaviorSubject} from 'rxjs';

@Component({
    standalone: true,
    imports: [SpinnerComponent, AsyncPipe, NgIf],
    selector: 'progress-component',
    styles: [`
      .progress-bar {
        position: relative;
        overflow: hidden;
      }

      .progress-bar::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
                        90deg,
                        rgba(233, 233, 233, 1) 0,
                        rgba(233, 233, 233, 0.9) 50%,
                        rgba(233, 233, 233, 0.8) 100%
        );
        animation: shimmer 3s ease-out infinite;
        content: "";
      }

      @keyframes shimmer {
        100% {
          transform: translateX(0%);
          opacity: 0;
        }
      }
    `],
    template: `
        <div class="absolute w-full -mt-1" style="z-index:9998 !important;" *ngIf="loading$|async">
            <div class="relative w-full top-1 h-2 progress-bar"></div>
        </div>
        <div *ngIf="blocking$|async" style="z-index: 99999 !important;"
             class="fixed h-full w-full inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div class="flex items-start justify-center min-h-screen pt-4 px-4 text-center">
                <div class="fixed inset-0 bg-white bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <spinner class="mt-10 h-10 w-10 text-dark" [icon]="icon"></spinner>
            </div>
        </div>
    `
})
export class ProgressComponent {
    @Input() blocking$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Input() loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Input() spinnerColors: string[] = ['black', 'black', 'black', 'black'];
    @Input() modalColor: string;
    @Input() icon: string;
}
