"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CmsSettings = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
class CmsSettings extends Base_1.Base {
    // status: any = {
    //     theme: false,
    //     fonts: false,
    //     favicon: false,
    //     icon: false,
    //     logo: false
    // }
    // deployments: any = {
    //     pending: 0,
    //     total: 0
    // }
    constructor(data, olMap) {
        super('settings', 9);
        // theme: ITheme = {};
        this.fonts = [];
        this.sites = [];
        // favicon: string = '';
        // icon: string = '';
        // logo: string = '';
        // logo_dark: string = '';
        // logo_light: string = '';
        this.images = {};
        (0, Common_1.setObjectProperties)(this, data, olMap, CmsSettings);
        // Make sure the 'Primary' site is in the sites array.
        this.sites = this.sites || [];
        if (!this.sites.find(s => s.id === 'default')) {
            this.sites.push({ label: 'Default', id: 'default' });
        }
    }
}
exports.CmsSettings = CmsSettings;
index_1.olm.settings = (ref, map) => {
    return new CmsSettings(ref, map);
};
