import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {from, switchMap, throwError,firstValueFrom} from "rxjs";

import {Auth, connectAuthEmulator, user as authUser, signOut, signInAnonymously, createUserWithEmailAndPassword,linkWithCredential, EmailAuthProvider} from '@angular/fire/auth';
import {authState} from 'rxfire/auth';

import {ClientService} from '../../shared/_services/client.service';

// @ts-ignore
import {environment} from '@env/environment';


@Injectable()
export class ConsumerAccountService {
    connected: boolean;

    constructor(
        private cSvc: ClientService,
        public auth: Auth
    ) {
        this.init();
    }

    async init() {
        this.cSvc.signedOut = authState(this.auth).pipe(filter(u => !u));
        authUser(this.auth).subscribe(
            async (user: any) => {
                if (user) {
                    this.cSvc.u$.next(user);
                } else {
                    this.cSvc.u$.next(null);
                    this.cSvc.user = null;
                }
            },
            async (err: any) => {
                console.warn(err);
                await this.signOut();
            }
        );
    }

    async signInAnon(retry?: number) {
        retry = retry || 0;
        if (retry < 3) {
            signInAnonymously(this.auth)
                .then(() => {
                    // Signed in..
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.signInAnon(retry+1);
                    },300);
                });
        } else {
            console.error('Anon long failed!');
        }
    }

    async signOut() {
        await signOut(this.auth);
    }

    //This method is to signup and link the account of a user that is currently annoynmous
    async signUp(email: string, password: string) {
        // creates an authentication credential object with the provided email and password
        const credential = EmailAuthProvider.credential(email, password);
        // returns an observable derived from the authState we are piping additional operators
        const user$ = authState(this.auth).pipe(
            // cool little RxJS operator that switches from the observable emitting the authetnication state to a new observable based on the condition of the current user's states.
            switchMap((user) => {
                //first check if there is a currently signed-in user and if that user is signed in anonymously.
                if (user && user.isAnonymous) {
                    // User is signed in anonymously, attempt to link with new credentials
                    return from(linkWithCredential(user, credential));
                } else if (!user) {
                    // No user is signed in, proceed with creating a new account
                    return from(createUserWithEmailAndPassword(this.auth, email, password));
                } else {
                    // User is already signed in and not anonymous, throw an error or handle accordingly
                    return throwError(() => new Error('Already signed in with a non-anonymous account'));
                }
            })
        )
        //apparently rxJS deprecated toPromise. This should convert the observable to a promise.
        //the observable that results from switchmap should be passed to firstValue from user$.
        return firstValueFrom(user$);
    }
}
