"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ad = exports.GOOGLE_STOCK_STATUS = exports.AD_TYPES = exports.AD_VENDORS = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
var AD_VENDORS;
(function (AD_VENDORS) {
    AD_VENDORS["google"] = "google";
    AD_VENDORS["bing"] = "bing";
    AD_VENDORS["facebook"] = "facebook";
})(AD_VENDORS || (exports.AD_VENDORS = AD_VENDORS = {}));
var AD_TYPES;
(function (AD_TYPES) {
    AD_TYPES["product"] = "product";
    AD_TYPES["search"] = "search";
    AD_TYPES["remarketing"] = "remarketing";
})(AD_TYPES || (exports.AD_TYPES = AD_TYPES = {}));
var GOOGLE_STOCK_STATUS;
(function (GOOGLE_STOCK_STATUS) {
    GOOGLE_STOCK_STATUS["In_Stock"] = "in_stock";
    GOOGLE_STOCK_STATUS["Out_Of_Stock"] = "out_of_stock";
    GOOGLE_STOCK_STATUS["Pre_Order"] = "preorder";
    GOOGLE_STOCK_STATUS["Back_Order"] = "backorder";
})(GOOGLE_STOCK_STATUS || (exports.GOOGLE_STOCK_STATUS = GOOGLE_STOCK_STATUS = {}));
class Ad extends Base_1.Base {
    constructor(data, olMap) {
        super('ads', 30);
        this.title = '';
        this.description = '';
        this.type = null;
        this.vendor = null;
        this.vendor_id = '';
        this.url = '';
        this.image = '';
        this.images = [];
        this.weight = null;
        this.brand = '';
        this.category = '';
        this.price = null;
        this.dynamic_price = null;
        this.stock = GOOGLE_STOCK_STATUS.In_Stock;
        this.product = null;
        this.old_id = '';
        this.published = [];
        (0, Common_1.setObjectProperties)(this, data, olMap, Ad);
    }
    getGoogleProductAdLine(domain, firstLine) {
        let line = 'id\ttitle\tlink\timage link\tdescription\tprice\tshipping weight\tbrand\tavailability\tMPN\tproduct_type\n';
        if (!firstLine) {
            let image = (this.image.match(/^http/)) ? this.image : `${domain}${this.image}`;
            let url = (this.url.match(/^http/)) ? this.url : `${domain}${this.url}`;
            if (!url.match(/\?/)) {
                url += `?adid=${this.id}`;
            }
            line = `${this.vendor_id}\t${this.title}\t${url}\t${image}\t${this.description.replace(/\n/g, '')}\t${this.price} USD\t${this.weight} lb\t${this.brand}\t${this.stock}\t${this.vendor_id}\t${this.category}\n`;
        }
        return line;
    }
    toString() {
        return `Ad: ${this.vendor}: ${this.title}`;
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.product = ((_a = this.product) === null || _a === void 0 ? void 0 : _a.toJSON) ? this.product.toJSON() : this.product;
        return r;
    }
}
exports.Ad = Ad;
index_1.olm.ads = (ref, map) => {
    return new Ad(ref, map);
};
