import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RcrtUser } from '@nxt/model-rcrt';
import {PageService} from '@library/shared/_services/page.service';
import {Router, RouterModule} from '@angular/router';
import {RcrtDataService} from '../../_services/rcrt-data.service';
import {IMenuItem} from '@nxt/model-core';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {slideUpDown} from '@library/shared/animations/slideUpDown';
import {pulse} from '@library/shared/animations/pulse';

@Component({
    selector: 'rcrt-player-dropdown',
    standalone: true,
    imports: [
        CommonModule, RouterModule
    ],
    animations: [slideUpDown,pulse],
    template: `
        <div [@slideUpDown] (click)="loadProfile()" *ngIf="user?.id">
            <button [@pulseAnimation]="pulseState" class="btn-clear btn-sm" *ngIf="!rdSvc.isPlayer">
                <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="user?.image?.src||'/assets/no-image-user.webp'">
                <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" *ngIf="player?.id" [src]="player?.image?.src||'/assets/no-image-user.webp'">
            </button>
            <button [@pulseAnimation]="pulseState" class="btn-clear btn-sm" *ngIf="rdSvc.isPlayer">
                <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="player?.image?.src||'/assets/no-image-user.webp'">
            </button>
        </div>
        <div *ngIf="user===null">
            <button routerLink="/login" class="btn-dark btn-sm mr-4">
                Sign Up/In
            </button>
        </div>
    `
})
export class RcrtPlayerDropdown extends OnDestroyPage {
    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @Input() label: string;
    @Input() player: RcrtUser;
    @Input() user: RcrtUser;
    get pulseState(): string {
        return this.show ? 'active' : 'inactive';
    }
    placement: 'top-right' = 'top-right';
    show: boolean = false;
    options: IMenuItem[] = [];

    constructor(
        public pSvc: PageService,
        public rdSvc: RcrtDataService,
        private router: Router,
        private lSvc: LocalStorageService
    ) {
        super();
    }

    hide() {
        this.show = false;
    }

    async loadProfile() {
        this.show = true;
        if (this.lSvc.localState?.lastPage) {
            this.router.navigateByUrl(this.lSvc.localState?.lastPage);
        } else {
            this.router.navigateByUrl(`/account`);
        }
        setTimeout(() => {
            this.show = false;
        }, 100);
    }

}
