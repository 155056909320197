import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';

import {IMenuItem} from '@nxt/model-core';
import {IAlert} from '../_services/page.service';
import {IconsComponent} from '../icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, IconsComponent],
    template: `
        <div class="body">
            <div *ngIf="alert?.icon==='red'"
                 class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <icon name="heroicon-outline-x" class="h-6 w-6 text-red-600"></icon>
            </div>
            <div *ngIf="alert?.icon==='green'"
                 class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <icon name="heroicon-outline-check" class="h-6 w-6 text-green-600"></icon>
            </div>
            <div class="pl-3 pr-3 pb-3">
                <div class="mt-2" *ngIf="html" [innerHTML]="html"></div>
                <div class="mt-2" *ngIf="!html">
                    <p class="text-sm text-gray-800">
                        {{ alert.message }}
                    </p>
                    <div class="item-list mb-10 p-0" *ngIf="alert?.options">
                        <ul role="list">
                            <ng-container *ngFor="let opt of alert.options">
                                <li>
                                    <a (click)="clickBtn(opt)" class="cursor-pointer hover:bg-gray-100">
                                        <div class="middle">
                                            <div class="dark mt-2">
                                                {{ opt.label }}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class AlertDialog {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() alert: IAlert;
    buttons: IMenuItem[];
    html: any;

    constructor(private router: Router) {
    }

    clickBtn(btn: any) {
        if (btn.click) {
            btn.click();
        }
        if (btn.routerLink) {
            this.router.navigate([btn.routerLink]);
        }
        if (btn.closeOnClick) {
            this.onClose.emit();
        }
    }
}
