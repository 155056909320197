import {Component, OnDestroy} from '@angular/core';
import {ReplaySubject} from 'rxjs';;

@Component({template: ''})
/**
 * MANY pages in our apps subscribe to things.  These subscriptions
 * must be removed when the user leaves the page or component. This
 * is ESSENTIAL to avoid memory leaks - zombie subscriptions that stick
 * around beyond their usefulness.
 */
export class OnDestroyPage implements OnDestroy {
    d$: ReplaySubject<boolean> = new ReplaySubject(1);

    ngOnDestroy() {
        this.d$.next(true);
        this.d$.complete()
    }

}
