import {Injectable} from '@angular/core';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ERcrtActions} from '@nxt/model-rcrt';
import {setDoc} from '@angular/fire/firestore';

@Injectable()
export class RcrtStatsService {
    collection: any;

    constructor(
        private fSvc: ConsumerFireService
    ) {

    }

    async track(user: any, action: ERcrtActions, params:any) {
        if (user?.uid
            && action
            && Object.keys(params||{}).length
            && params.player_id !== user?.uid
            && params.player_id !== user?.collab
        ) {
            let id: string = `${action}-${Object.values(params).join('-')}`;
            let stat: string = `stats/${user.uid}/actions/${id}`;
            let doc = await this.fSvc.getDoc(stat).toPromise();
            if (!doc.exists()) {
                let data: any = Object.assign({action:action}, params);
                data.date = Date.now();
                await setDoc(doc.ref, data);
            }
        }
    }
}
