import {Component, HostListener} from '@angular/core';
import {Router} from '@angular/router';

import {PageService} from '@library/shared/_services/page.service';
import {ThemeService} from '@library/shared/_services/theme.service';
import {environment} from '@env/environment';

@Component({
    selector: 'app-root',
    template: ``
})
export class RootConsumerAppComponent {
    favIcon: HTMLLinkElement = document.querySelector('#favicon');

    constructor(
        public pSvc: PageService,
        tSvc: ThemeService,
        private router: Router) {
        tSvc?.setTheme(environment.default_client.theme, this.favIcon);
    }

    @HostListener('document:click', ['$event']) handleClick(e) {
        this.pSvc.click$.next(e);
        // Detect platform
        if (e.target['getAttribute']('data-url')) {
            e.preventDefault();
            if (e.target['getAttribute']('target') === '_blank') {
                window.open(e.target['getAttribute']('data-url'), "_blank");
            } else {
                this.router.navigate([e.target['getAttribute']('data-url')]);
            }
        } else if (e.target['getAttribute']('href') && e.target['getAttribute']('href').match(/^tel/)) {
            // Click to call event here.
            // this.aSvc.track('mixpanel', 'Clicked to Call', {'Number': environment.prettyPhone });
            // this.aSvc.track('ga', 'config', {'phone_conversion_number': environment.prettyPhone, 'send_to': environment.awid+'/m2MpCP7Gw-oBEJmFlsAC'});

        } else if (e.target['getAttribute']('href') && e.target['getAttribute']('href').match(/^mailto/)) {
            // Click to email event here.
            // this.aSvc.track('mixpanel', 'Clicked to Email',
            //     {
            //         'Address': e.target['getAttribute']('href').split('?')[0].replace('mailto:', ''),
            //         'Subject': e.target['getAttribute']('href').split('?')[1].replace('subject=', '').replaceAll('%20', ' ')
            //     });
        }
    }

    @HostListener('window:keydown', ['$event']) handleKey(e) {
        if (e) {
            let charCode = String.fromCharCode(e.which).toLowerCase();
            if ((navigator.platform.match('Mac') && e.metaKey && charCode === 's') || (!navigator.platform.match('Mac') && e.ctrlKey && charCode === 's')) {
                e.preventDefault();
                // this.pSvc.clickSave$.next(true);
            } else if (e.key === 'Escape') {
                this.pSvc.clickEsc$.next(true);
            }
        }
    }
}
