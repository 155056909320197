"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Token = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Token extends Base_1.Base {
    constructor(data, olMap) {
        super('_tokens', 10);
        this.token = '';
        this.expires = null;
        this.key = '';
        this.path = '';
        this.email = '';
        this.contact = null;
        this.consumer_user_id = '';
        (0, Common_1.setObjectProperties)(this, data, olMap, Token);
    }
}
exports.Token = Token;
index_1.olm._tokens = (ref, map) => {
    return new Token(ref, map);
};
