import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Feedback, IMenuItem, round} from '@nxt/model-core';
import {FireService, IFirestoreQuery} from '../_services/fire.service';
import {ClientService} from '../../shared/_services/client.service';
import {FooterButtonsComponent} from '../footer/footer-buttons.component';
import {FormsModule} from '@angular/forms';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule, FooterButtonsComponent, FormsModule],
    selector: 'feedback-items',
    template: `
        <div class="px-4" *ngIf="status$|async">
            <h4 class="sr-only">Status</h4>
            <div class="mt-4" aria-hidden="true">
                <div class="overflow-hidden rounded-full bg-gray-200">
                    <div class="h-2 rounded-full bg-dark" [style.width]="(completed$|async)+'%'"></div>
                </div>
                <div class="mt-2 text-xs font-medium text-gray-600">
                    <div class="text-indigo-600">{{ status$|async }}</div>
                </div>
            </div>
        </div>
        <div class="p-4 pb-10 min-h-300 max-h-screen-header overflow-y-scroll" *ngIf="feedbackId">
            <div #lineItems class="italic text-xs" *ngFor="let line of lines">{{ line }}</div>
            <button class="btn-gray btn-sm" *ngIf="lastItem" (click)="connect()">More</button>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>

    `
})
export class FeedbackItemsComponent extends OnDestroyPage implements OnChanges {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('lineItems') lineItems: QueryList<ElementRef>;

    @Input() feedbackId: string;
    completed$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    status$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    id: string;
    feedback: Feedback[] = [];
    title: string;
    exists: boolean;
    buttons: IMenuItem[] = [
        {
            label: 'OK',
            
            click: () => {
                this.delete();
            }
        }
    ];
    lines: string[];
    sub: Subscription;
    lastItem: any;

    constructor(
        private fSvc: FireService,
        private cSvc: ClientService
    ) {
        super();
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.delete();
    }

    ngOnChanges(changes?:SimpleChanges) {
        this.connect();
    }

    connect() {
        console.log('feedbackId',this.feedbackId);
        if (this.feedbackId) {

            let limit: number = 1000;
            this.sub?.unsubscribe();
            let query:IFirestoreQuery[] = [
                {name: 'where', args: ['group_id', '==', this.feedbackId]},
                {name: 'orderBy', args: ['date', 'asc']},
                {name: 'limit', args: [limit]}
            ];
            if (this.lastItem) {
                console.log('startAfter');
                query.push({name: 'startAfter', args: [this.lastItem]});
            }

            this.sub = this.fSvc.watchColl(`clients/${this.cSvc.client_id}/feedback`, query, this.d$)
                .subscribe(
                    res => {

                        this.lines = res.map(item =>   {
                            let data: any = item.data();
                            if (data.percent !== undefined && Number(data.percent) > (this.completed$.getValue()||0)) {
                                this.completed$.next( Number(data.percent));
                                this.status$.next(data.title);
                                console.log(data.title);
                            }
                            return `${item.data().title}${item.data().description ? ' - '+ item.data().description : ''}`
                        });
                        if (limit === this.lines.length) {
                            this.lastItem = res[res.length-1];
                        } else {
                            this.lastItem = null;
                        }
                        this.scrollTo();
                    }
                );
        }
    }

    async delete() {
        this.onClose.emit();
        try {
            let result: any = await this.cSvc.callAPI(`/cms/feedback/${this.feedbackId}`, 'delete');
        } catch (e) {
            console.warn(e);
        }
    }


    scrollTo() {
        let l: number = this.lineItems.toArray().length;
        let el = this.lineItems.toArray()[l-1];
        if (el) {
            el.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

}
