import {ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'icon',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div [ngClass]="'block '+class" [innerHTML]="svgIcon"></div>
    `
})
export class IconsComponent implements OnChanges {
    @Input() name: string;
    @Input() class: string;
    @Input() title: string;
    @Input() strokeWidth: string = '2';
    svgIcon: any;

    constructor(
        private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
        private changeRef: ChangeDetectorRef
    ) {
    }

    public ngOnChanges(): void {
        if (!this.name) {
            this.svgIcon = '';
            return;
        }
        this.httpClient
            .get(`/assets/_icons/${this.name}.svg`, { responseType: 'text' })
            .subscribe(value => {
                value = this.updateStrokeWidth(value, this.strokeWidth);
                this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
                this.changeRef.detectChanges();
            });
    }
    private updateStrokeWidth(svg: string, strokeWidth: string): string {
        return svg.replace(/stroke-width="[^"]*"/g, `stroke-width="${strokeWidth}"`);
    }
}
