import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Address} from '@nxt/model-core';
import {RcrtCart,RcrtOrderItem} from '@nxt/model-rcrt';

import {CartService, ICartDocument} from '@library/shared/_services/cart.service';

@Injectable()
export class RcrtCartService extends CartService {
    cartDocument$: BehaviorSubject<ICartDocument> = new BehaviorSubject<ICartDocument>(null);
    activeItem$: BehaviorSubject<RcrtOrderItem> = new BehaviorSubject<RcrtOrderItem>(null);
    _calculations: any[] = [];

    loadDocument(document: ICartDocument): ICartDocument {
        super.loadDocument(document);
        document.checkout = new RcrtCart(document.checkout);
        document.checkout?.calc();
        return document;
    }

    emitDocument(c: any) {
        let document: ICartDocument = c.exists() ? c.data() : {};
        document.exists = c.exists();
        this.loadDocument(document);
        this.cartDocument$.next(document);
    }

    async calculate(item: RcrtOrderItem) {
        this.calculating$.next(true);
        item.quantity = item.quantity || 1;
        this.calculating$.next(false);
    }

    async calculateShipping(latest: ICartDocument, a?: Address) {
        // We have to run calculation every time even without an address
        // because we check order totals and whether it's the weekend or evening
        // to set flags like ltlRcrtOrder$, largeRcrtOrder$, etc - something not done on EWIN.
        return super.calculateShipping(latest, a, true);
    }

    copyCart(document: ICartDocument): ICartDocument {
        if (document) {
            let copy: any = JSON.parse(JSON.stringify(this.toFullJSON(document)));
            return this.loadDocument(copy);
        }
        return document;
    }

    inCart(item: RcrtOrderItem, cart: ICartDocument) {
        return !!this.findItem(item, cart);
    }

    findIndex(item: RcrtOrderItem, cart: ICartDocument): number {
        return cart?.checkout?.items?.findIndex(i => i?.sku === item?.sku);
    }

}
