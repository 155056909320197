import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Activity, olm, RcrtUser} from '@nxt/model-rcrt';
import {CommonModule} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {RcrtDataService} from '../../_services/rcrt-data.service';

@Component({
    selector: 'rcrt-activity-item',
    standalone: true,
    imports: [
        CommonModule,
        PipesModule
    ],
    template: `
        <li class="rcrt-list-item flex border-b border-gray-200 min-h-10" (click)="onClick.emit(item)">
            <div class="w-full flex justify-between p-1">
                <div class="flex space-x-3">
                    <div class="flex shrink-0 -space-x-1">
                        <img class="h-8 w-8 bg-gray-100 rounded-md ring-1 ring-white" [src]="item?.info?.src||'/assets/empty.png'" alt="">
                        <div *ngIf="item?.info?.unread" class="bg-red-700 h-3 w-3 text-white text-xxs flex place-content-center rounded-md ml-1">&nbsp;</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-sm font-semibold text-gray-900">
                            {{ item?.info?.label }} <span [innerHTML]="item?.info?.emoji"></span>
                        </div>
                        <div class="text-xs text-gray-500" [innerHTML]="html"></div>
                    </div>
                </div>
                <div class="flex flex-col text-right text-xxs italic text-gray-500 uppercase whitespace-nowrap">
                    <div>{{ item?.date | dateAgo }}</div>
                </div>
            </div>
        </li>
    `
})
export class RcrtActivityItem implements OnChanges {
    @Output() onClick: EventEmitter<Activity> = new EventEmitter();
    @Input() item: Activity;
    @Input() player: RcrtUser;
    @Input() user: RcrtUser;
    html: any;

    constructor(
        private sanitizer: DomSanitizer,
        private fSvc: ConsumerFireService,
        private rdSvc: RcrtDataService
    ) {

    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.item && this.player && this.user) {
            let opts: any = this.fSvc.loadAllOpts(olm);
            await this.item.setInfo(opts, this.player);
            this.item.info.unread = (this.rdSvc.last_check && this.item.date > this.rdSvc.last_check && this.item.user_id !== this.user.id);
            if (this.item?.info?.description?.length) {
                let parts: string[] = this.item?.info?.description.split('\n');
                let count: number = 0;
                let desc: string = parts.reduce((html, str) => {
                    if (count < 200) {
                        count += str.length;
                        html.push(`<div class="mb-1">${str}</div>`);
                    }
                    return html;
                },[]).join('');
                this.html = this.sanitizer.bypassSecurityTrustHtml(`<div class="flex flex-col">${desc}</div>`);
            }
        }
    }
}
