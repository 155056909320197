"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripeInvoice = exports.StripeSubscription = exports.EStripeSubscriptionStatus = exports.StripePaymentIntent = exports.StripeCharge = void 0;
const index_1 = require("../index");
class StripeCharge extends index_1.Base {
    constructor(data, olMap) {
        super('charges', null);
        this.object = ""; // "charge",
        this.amount = null; // 2000,
        this.amount_captured = null; // 0,
        this.amount_refunded = null; // 0,
        this.balance_transaction = ''; // "txn_1032HU2eZvKYlo2CEPtcnUvl",
        this.billing_details = {};
        this.calculated_statement_descriptor = ''; // null,
        this.captured = null; // false,
        this.created = null; // 1609337356,
        this.currency = ''; // "usd",
        this.customer = ''; //,
        this.description = ''; // "My First Test Charge (created for API docs)",
        this.disputed = null; //false,
        this.failure_code = null; // null,
        this.failure_message = ''; // null,
        this.fraud_details = {}; //
        this.invoice = null; // null,
        this.livemode = null; // false,
        this.metadata = {}; // {},
        this.on_behalf_of = null; // null,
        this.status = ''; // succeeded, pending
        this.order = null; // null,
        this.outcome = null;
        this.paid = null; // true,
        this.payment_intent = null;
        this.payment_method = ''; // "pm_1I45MW2eZvKYlo2CO3ocmdTD",
        this.payment_method_details = {};
        this.receipt_email = ''; // null,
        this.receipt_number = null; //,
        this.receipt_url = ''; // "https://pay.stripe.com/receipts/acct_1032D82eZvKYlo2C/ch_1I45MW2eZvKYlo2CPdomT0G8/rcpt_IfQCGc8qRgwaUEbSMnVftQCHny8LFjv",
        this.refunded = null; // false,
        this.refunds = {};
        this.review = null;
        this.shipping = null;
        this.source_transfer = null;
        this.statement_descriptor = null;
        this.statement_descriptor_suffix = null;
        this.transfer_data = null;
        this.transfer_group = null;
        this.source = ''; // "tok_mastercard"
        (0, index_1.setObjectProperties)(this, data, olMap, StripeCharge);
    }
    get dateCreated() {
        return new Date(this.created * 1000);
    }
}
exports.StripeCharge = StripeCharge;
class StripePaymentIntent extends index_1.Base {
    constructor(data, olMap) {
        super('payment_intents', null);
        this.id = '';
        this.parent = null;
        this.contact = null;
        this.account = null;
        this.metadata = {};
        this.object = '';
        this.amount = null;
        this.amount_capturable = null;
        this.amount_details = null;
        this.amount_received = null;
        this.application = '';
        this.application_fee_amount = null;
        this.automatic_payment_methods = null;
        this.canceled_at = null;
        this.cancellation_reason = '';
        this.capture_method = '';
        this.client_secret = '';
        this.confirmation_method = null;
        this.created = null;
        this.currency = '';
        this.customer = '';
        this.description = '';
        this.invoice = '';
        this.last_payment_error = null;
        this.latest_charge = '';
        this.livemode = null;
        this.next_action = null;
        this.on_behalf_of = '';
        this.payment_method = '';
        this.payment_method_options = null;
        this.payment_method_types = null;
        this.processing = null;
        this.receipt_email = '';
        this.review = '';
        this.setup_future_usage = null;
        this.shipping = null;
        this.statement_descriptor = '';
        this.statement_descriptor_suffix = '';
        this.status = null;
        this.transfer_data = null;
        this.transfer_group = '';
        // Added by me to store other important bits.
        this.payment_method_data = null;
        this._m = ['status'];
        (0, index_1.setObjectProperties)(this, data, olMap, StripePaymentIntent);
    }
    toString() {
        return `Intent: `;
    }
}
exports.StripePaymentIntent = StripePaymentIntent;
var EStripeSubscriptionStatus;
(function (EStripeSubscriptionStatus) {
    EStripeSubscriptionStatus["INCOMPLETE"] = "incomplete";
    EStripeSubscriptionStatus["INCOMPLETE_EXPIRED"] = "incomplete_expired";
    EStripeSubscriptionStatus["TRIALING"] = "trialing";
    EStripeSubscriptionStatus["ACTIVE"] = "active";
    EStripeSubscriptionStatus["PAST_DUE"] = "past_due";
    EStripeSubscriptionStatus["CANCELED"] = "canceled";
    EStripeSubscriptionStatus["UNPAID"] = "unpaid";
    EStripeSubscriptionStatus["NONE"] = "";
})(EStripeSubscriptionStatus || (exports.EStripeSubscriptionStatus = EStripeSubscriptionStatus = {}));
class StripeSubscription extends index_1.Base {
    constructor(data, olMap) {
        super('stripe_subscriptions', null);
        this.id = '';
        this.object = '';
        this.application = '';
        this.application_fee_percent = null; // >= 0
        this.billing_cycle_anchor = null;
        this.billing_thresholds = null;
        this.cancel_at = null;
        this.cancel_at_period_end = null;
        this.canceled_at = null;
        this.cancellation_details = null;
        this.collection_method = '';
        this.created = null; // 1687981713,
        this.currency = ''; // usd,
        this.current_period_end = null;
        this.current_period_start = null;
        this.customer = ''; // cus_OAPAwLXCDiEHYL
        this.days_until_due = null;
        this.default_payment_method = '';
        this.default_source = '';
        this.default_tax_rates = null;
        this.description = '';
        this.discount = null;
        this.ended_at = null;
        this.items = null;
        this.latest_invoice = null;
        this.livemode = null;
        this.metadata = {};
        this.pause_collection = null;
        this.pending_setup_intent = '';
        this.pending_update = null;
        this.schedule = null;
        this.start_date = null; // 1687981713,
        this.status = EStripeSubscriptionStatus.NONE;
        this.test_clock = '';
        this.transfer_data = null;
        this.trial_end = null; // 1688586513,
        this.trial_settings = null;
        this.trial_start = null;
        // Houses the reference to the payment
        this.stripe_invoices = new index_1.ChildArray();
        this._m = ['status'];
        (0, index_1.setObjectProperties)(this, data, olMap, StripeSubscription);
        this.convert();
    }
    toString() {
        return `Subscription: `;
    }
    async convert(opts) {
        var _a, _b;
        if (((_b = (_a = this.start_date) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.length) === 10) {
            this.start_date = this.start_date * 1000;
        }
    }
    async save(parent) {
        await this.convert();
        return super.save(parent);
    }
}
exports.StripeSubscription = StripeSubscription;
class StripeInvoice extends index_1.Base {
    constructor(data, olMap) {
        super('stripe_invoices', null);
        this.id = ''; //in_1NviYhB0ZvawBcR6EhP4gfv6",
        this.object = ''; //invoice",
        this.account_country = ''; //US",
        this.account_name = ''; //Aerospace Technology Ventures, LLC",
        this.account_tax_ids = [];
        this.amount_due = null;
        this.amount_paid = null;
        this.amount_remaining = null;
        this.amount_shipping = null;
        this.application = '';
        this.application_fee_amount = null;
        this.attempt_count = null;
        this.attempted = null;
        this.auto_advance = null;
        this.automatic_tax = {};
        this.billing_reason = ''; //manual",
        this.charge = '';
        this.collection_method = ''; //charge_automatically",
        this.created = null;
        this.currency = ''; //usd",
        this.custom_fields = '';
        this.customer = ''; //cus_IxCIWkNTvmBMAQ",
        this.customer_address = '';
        this.customer_email = ''; //stuart@younghome.net",
        this.customer_name = ''; //Home Stu",
        this.customer_phone = '';
        this.customer_shipping = '';
        this.customer_tax_exempt = ''; //none",
        this.customer_tax_ids = [];
        this.default_payment_method = '';
        this.default_source = '';
        this.default_tax_rates = [];
        this.description = '';
        this.discount = '';
        this.discounts = [];
        this.due_date = '';
        this.effective_at = '';
        this.ending_balance = '';
        this.footer = '';
        this.from_invoice = '';
        this.hosted_invoice_url = '';
        this.invoice_pdf = '';
        this.last_finalization_error = '';
        this.latest_revision = '';
        this.lines = null;
        this.livemode = null;
        this.next_payment_attempt = '';
        this.number = '';
        this.on_behalf_of = '';
        this.paid = null;
        this.paid_out_of_band = null;
        this.payment_intent = null;
        this.period_end = null;
        this.period_start = null;
        this.post_payment_credit_notes_amount = null;
        this.pre_payment_credit_notes_amount = null;
        this.quote = '';
        this.receipt_number = '';
        this.rendering = '';
        this.shipping_cost = '';
        this.shipping_details = '';
        this.starting_balance = null;
        this.statement_descriptor = '';
        this.status = ''; //draft",
        this.subscription = '';
        this.subtotal = null;
        this.subtotal_excluding_tax = null;
        this.tax = '';
        this.test_clock = '';
        this.total = null;
        this.total_excluding_tax = null;
        this.total_tax_amounts = [];
        this.transfer_data = '';
        this.webhooks_delivered_at = '';
        this._m = ['status'];
        (0, index_1.setObjectProperties)(this, data, olMap, StripeInvoice);
    }
    toString() {
        return `Invoice: `;
    }
}
exports.StripeInvoice = StripeInvoice;
index_1.olm.stripe_subscriptions = (ref, map) => {
    return new StripeSubscription(ref, map);
};
index_1.olm.stripe_invoices = (ref, map) => {
    return new StripeInvoice(ref, map);
};
