"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Role extends Base_1.Base {
    constructor(data, olMap) {
        super('roles', 9);
        this.label = '';
        this.description = '';
        (0, Common_1.setObjectProperties)(this, data, olMap, Role);
    }
}
exports.Role = Role;
index_1.olm.roles = (ref, map) => {
    return new Role(ref, map);
};
