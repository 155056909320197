"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tour = exports.TooltipAnimation = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
var TooltipAnimation;
(function (TooltipAnimation) {
    TooltipAnimation["FADE_IN"] = "fade-in";
    TooltipAnimation["SLIDE_IN_TOP"] = "slide-in-top";
    TooltipAnimation["SLIDE_IN_BOTTOM"] = "slide-in-bottom";
    TooltipAnimation["ZOOM_IN"] = "zoom-in";
})(TooltipAnimation || (exports.TooltipAnimation = TooltipAnimation = {}));
class Tour extends Base_1.Base {
    constructor(data, olMap) {
        super('tours', 18);
        this.tour_name = '';
        this.steps = [];
        this.stepDuration = 0;
        this.showNavigationButtons = true;
        this.showNext = true;
        this.showPrevious = true;
        this.tooltipClass = '';
        this.customTooltipStyle = {};
        this.disableOnMobile = true;
        this.animationClass = TooltipAnimation.FADE_IN;
        this._m = ['tour_name', 'date'];
        (0, Common_1.setObjectProperties)(this, data, olMap, Tour);
    }
}
exports.Tour = Tour;
index_1.olm.tours = (ref, map) => {
    return new Tour(ref, map);
};
