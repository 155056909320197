"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Discount = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Discount extends Base_1.Base {
    constructor(data, olMap) {
        super('discounts', 18);
        this.type = '';
        this.date = null;
        this.public = false;
        this.shipping = false;
        this.start = null;
        this.end = null;
        this.value = null;
        this.amount = null;
        this.reusable = false;
        this.used = false;
        this.code = '';
        this.title = '';
        this.description = '';
        this.author = '';
        this.author_id = '';
        this.user_id = '';
        this.manual = false;
        this.locked = false;
        this.universal = false;
        this.fn = '';
        (0, Common_1.setObjectProperties)(this, data, olMap, Discount);
    }
    calc(order) {
        switch (this.type) {
            case 'percentage':
                let discountable = order.subtotal || 0;
                if (this.shipping && order.shipping) {
                    discountable += order.shipping;
                }
                this.amount = (0, Common_1.round)(((this.value || 0) / 100) * discountable, 2);
                break;
            case 'flat':
                this.amount = this.value || 0;
                break;
            case 'logic':
                if (this.fn) {
                    let fn = Function('order', this.fn);
                    this.amount = fn(order);
                    if (isNaN(this.amount)) {
                        this.amount = 0;
                    }
                    else {
                        this.amount = (0, Common_1.round)(this.amount, 2);
                    }
                }
                break;
        }
    }
    toMinJSON(ignoreDocRef) {
        return this.toJSON();
    }
}
exports.Discount = Discount;
index_1.olm.discounts = (ref, map) => {
    return new Discount(ref, map);
};
