"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RcrtEventRef = exports.RcrtEventTeam = exports.RcrtEventScout = exports.RcrtEvent = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class RcrtEvent extends model_core_1.Base {
    get dates() {
        let r = '';
        if (this.start) {
            let sY = (0, model_core_1.formatDate)(this.start, 'yyyy');
            let sM = (0, model_core_1.formatDate)(this.start, 'MMMM');
            if (this.end) {
                let eY = (0, model_core_1.formatDate)(this.end, 'yyyy');
                let eM = (0, model_core_1.formatDate)(this.end, 'MMMM');
                if (eY !== sY) {
                    r = `${(0, model_core_1.formatDate)(this.start, 'MMMM d yyyy')} - ${(0, model_core_1.formatDate)(this.end, 'MMMM d yyyy')}`;
                }
                else if (eM !== sM) {
                    r = `${(0, model_core_1.formatDate)(this.start, 'MMMM d')} - ${(0, model_core_1.formatDate)(this.end, 'MMMM d')}, ${sY}`;
                }
                else {
                    r = `${(0, model_core_1.formatDate)(this.start, 'MMMM d')}-${(0, model_core_1.formatDate)(this.end, 'd')}, ${sY}`;
                }
            }
            else {
                r = `${(0, model_core_1.formatDate)(this.start, 'MMMM d')}, ${sY}`;
            }
        }
        return r;
    }
    constructor(data, olMap) {
        super('events', 14);
        this.title = '';
        this.description = '';
        this.location = '';
        this.start = null;
        this.end = null;
        this.links = [];
        this.sport = '';
        this.gender = null;
        this.image = null;
        this.state = '';
        this.city = '';
        this.tz = '';
        this.errors = [];
        this.errorCount = 0;
        this.counts = { scouts: 0, teams: 0 };
        this.teams = new model_core_1.ChildArray();
        this.scouts = new model_core_1.ChildArray();
        this._m = ['title', 'image'];
        this._s = ['active', 'title', 'image', 'counts', 'description', 'location', 'gender', 'sport', 'errorCount', 'links', 'start', 'end'];
        data = (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtEvent);
        index_1.eventLinkTypes.forEach(id => {
            if (!this.getLink(id)) {
                this.add('links', { id: id, url: '', lock: 0, error: index_1.RCRT_SCRAPING_ERRORS.MISSING_URL });
            }
        });
    }
    getLink(id) {
        var _a;
        return (_a = this.links) === null || _a === void 0 ? void 0 : _a.find(l => l.id === id);
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (!opts.properties) {
            opts.properties = [];
        }
        await super.loadAll(opts, stack, p);
        // Sort teams by title
        if ((_a = this.teams) === null || _a === void 0 ? void 0 : _a.length) {
            this.teams.sort((a, b) => a.title < b.title ? -1 : 1);
        }
    }
    toJSON() {
        var _a, _b;
        let r = super.toJSON();
        // Proactively set errors to null when there are none so we can query for records with errors (e.g. where('errors','!=',null));
        if (!((_a = this.errors) === null || _a === void 0 ? void 0 : _a.length)) {
            r.errors = null;
        }
        r.errorCount = ((_b = this.errors) === null || _b === void 0 ? void 0 : _b.length) || 0;
        return r;
    }
    save(parent) {
        if (!this.id) {
            throw `Cannot save event without id.`;
        }
        this.last_date = Date.now();
        return super.save(parent);
    }
    sharePath() {
        return `/public/ev/${this._docRef.parent.parent.id}/${this.id}`;
    }
}
exports.RcrtEvent = RcrtEvent;
class RcrtEventScout extends model_core_1.Base {
    constructor(data, olMap) {
        super('scouts', 14);
        this.title = ''; // Place to store school name when we can't resolve it with our college table via search.
        this.staff = [];
        this.ref = null;
        this.college = null;
        this._m = ['name'];
        data = (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtEventScout);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.college = new index_1.College(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a;
        if ((_a = this.college) === null || _a === void 0 ? void 0 : _a._docRef) {
            this.id = this.college.id;
            this.active = (this.active !== null) ? this.active : true;
            this.last_date = Date.now();
            this.ref = this.college._docRef;
        }
        let r = super.toJSON();
        delete r.college;
        return r;
    }
}
exports.RcrtEventScout = RcrtEventScout;
class RcrtEventTeam extends model_core_1.Base {
    constructor(data, olMap) {
        super('teams', 14);
        this.name = '';
        this.title = '';
        this.links = [];
        this.image = null;
        this.games = [];
        this.gender = null;
        this.sport = '';
        this._m = ['name', 'image'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtEventTeam);
        if (this.title && !this.name) {
            this.name = this.title;
        }
    }
    getLink(id) {
        var _a;
        return (_a = this.links) === null || _a === void 0 ? void 0 : _a.find(l => l.id === id);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
}
exports.RcrtEventTeam = RcrtEventTeam;
class RcrtEventRef extends model_core_1.Base {
    get results() {
        return {
            emails: this.results_emails,
            shares: this.results_shares,
            views: this.results_views,
            replies: this.results_replies,
            clicks: this.results_clicks
        };
    }
    constructor(data, olMap) {
        super('event_refs', 14);
        this.event_date = null;
        this.ref = null;
        this.teamRef = null;
        this.team = null;
        this.games = [];
        this.notes = '';
        this.event = null;
        this.linkedTeam = null;
        this.college_refs = new model_core_1.ChildArray();
        this.shares = new model_core_1.ChildArray();
        this.counts = { colleges: 0, archived: 0 };
        this.results_emails = 0;
        this.results_shares = 0;
        this.results_views = 0;
        this.results_clicks = 0;
        this.results_replies = 0;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, RcrtEventRef);
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.olm = opts.olm || index_1.olm;
        if (this.ref) {
            this.event = new RcrtEvent(opts.getDoc ? await opts.getDoc(this.ref) : await this.ref.get(), index_1.olm);
        }
        if (this.teamRef) {
            this.linkedTeam = new RcrtEventTeam(opts.getDoc ? await opts.getDoc(this.teamRef) : await this.teamRef.get(), index_1.olm);
        }
        await super.loadAll(opts, stack, p);
    }
    toJSON() {
        var _a, _b, _c, _d;
        if (!((_a = this.event) === null || _a === void 0 ? void 0 : _a._docRef)) {
            throw `Cannot save RcrtEventRef reference until it has a docRef!`;
        }
        else {
            this.id = this.event.id;
            this.active = (this.active !== null) ? this.active : true;
            this.last_date = Date.now();
            this.ref = this.event._docRef;
        }
        if ((_b = this.linkedTeam) === null || _b === void 0 ? void 0 : _b._docRef) {
            this.teamRef = this.linkedTeam._docRef;
            if ((_d = (_c = this.linkedTeam) === null || _c === void 0 ? void 0 : _c.games) === null || _d === void 0 ? void 0 : _d.length) {
                this.games = [];
            }
        }
        let r = super.toJSON();
        delete r.event;
        delete r.linkedTeam;
        return r;
    }
}
exports.RcrtEventRef = RcrtEventRef;
index_1.olm.event_refs = (ref, map) => {
    return new RcrtEventRef(ref, map);
};
index_1.olm.events = (ref, map) => {
    return new RcrtEvent(ref, map);
};
index_1.olm.teams = (ref, map) => {
    return new RcrtEventTeam(ref, map);
};
index_1.olm.scouts = (ref, map) => {
    return new RcrtEventScout(ref, map);
};
