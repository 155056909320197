"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetricId = exports.Metric = exports.GRANULARITY = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
var GRANULARITY;
(function (GRANULARITY) {
    GRANULARITY["DAY"] = "day";
    GRANULARITY["WEEK"] = "week";
    GRANULARITY["MONTH"] = "month";
    GRANULARITY["YEAR"] = "year";
})(GRANULARITY || (exports.GRANULARITY = GRANULARITY = {}));
;
class Metric extends Base_1.Base {
    constructor(data, olMap) {
        super('metrics', null);
        this.year = null;
        this.month = null;
        this.dayOfMonth = null;
        this.week = null;
        this.dayOfWeek = null;
        this.dayOfYear = null;
        this.categories = [];
        this.name = '';
        this.value = 0;
        this.keys = [];
        (0, Common_1.setObjectProperties)(this, data, olMap, Metric);
    }
    async save(parent) {
        var _a;
        // All the identifiers must be set by calling application
        if (!this.id) {
            this.id = getMetricId(this);
        }
        if (this.name.match(/\//)) {
            throw `Metric name cannot contact a slash.`;
        }
        if ((_a = this.categories) === null || _a === void 0 ? void 0 : _a.join('-').match(/\//)) {
            throw `Metric category value cannot contact a slash.`;
        }
        return super.save(parent);
    }
}
exports.Metric = Metric;
function getMetricId(obj) {
    return ['year', 'month', 'dayOfMonth', 'week', 'dayOfWeek', 'dayOfYear'].map(prop => {
        return obj[prop] || '';
    }).concat(obj.categories).join('-') + '-' + obj.name;
}
exports.getMetricId = getMetricId;
index_1.olm.metrics = (ref, map) => {
    return new Metric(ref, map);
};
