import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

export const pulse = [
    trigger('pulseAnimation', [
        state('inactive', style({
            transform: 'scale(1)'
        })),
        state('active', style({
            transform: 'scale(1)'
        })),
        transition('inactive <=> active', [
            animate('.5s ease-in-out', keyframes([
                style({ transform: 'scale(1)', offset: 0 }),
                style({ transform: 'scale(1.2)', offset: 0.5 }),
                style({ transform: 'scale(1)', offset: 1 })
            ]))
        ])
    ])
];
